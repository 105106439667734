import { Box, styled, Typography } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    marginTop: '8rem'
  }
}));

type MessageProp = {
  title: string;
  message: string;
};

const Message = ({ title, message }: MessageProp) => (
  <Wrapper>
    <Typography variant="h4" component="h2">
      {title}
    </Typography>
    <Typography>{message}</Typography>
  </Wrapper>
);

export default Message;
