import { GoogleAnalyticsEventId, GoogleAnalyticsEventName } from '@/constants/types';
import useGoogleTagManager from '@/hooks/useGoogleTagManager';
import Alternaleaf from '@/pages/components/Alternaleaf';
import PreConsultButton from '@/pages/components/PreConsultButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import { Box, Breadcrumbs, Card, Chip, styled } from '@mui/material';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';

const SummaryHeading = styled(Typography)({
  fontWeight: 600,
  marginBottom: 16
}) as typeof Typography;

const StyledTypography = styled(Typography)({
  color: 'text.primary',
  display: 'flex',
  alignItems: 'center'
});

const StyledGridContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: 20,
    marginLeft: 0,
    width: '100%'
  },

  marginTop: 0
}));

const StyledCard = styled(Card)(() => ({
  padding: '2rem',
  borderRadius: '20px',
  backgroundColor: '#F0EDE4',
  display: 'flex',
  flexDirection: 'column'
}));

const BreadCrumbsContainer = styled(Grid)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block'
  }
}));

interface PrescreeningAndBookingCompletedProps {
  email?: string;
  bookingStartDateTime?: Date;
}

export default function PrescreeningAndBookingCompleted({
  email,
  bookingStartDateTime
}: PrescreeningAndBookingCompletedProps) {
  const { sendGoogleAnalyticsEvent } = useGoogleTagManager();

  useEffect(() => {
    sendGoogleAnalyticsEvent(GoogleAnalyticsEventName.PRESCREENING_AND_BOOKING_COMPLETED, {
      id: GoogleAnalyticsEventId.PRESCREENING_AND_BOOKING_COMPLETED,
      status: 'Successful'
    });
  }, [sendGoogleAnalyticsEvent]);

  function StepsBreadCrumb({ children }: { children: React.ReactNode }) {
    return (
      <Breadcrumbs
        sx={{
          '& .MuiBreadcrumbs-separator': {
            fontSize: '1.5rem',
            lineHeight: '1rem'
          }
        }}
        separator="-"
        aria-label="breadcrumb"
      >
        {children}
      </Breadcrumbs>
    );
  }

  function CheckCircle({ text }: { text: string }) {
    return (
      <StyledTypography>
        <CheckCircleIcon color="success" sx={{ height: '2.4rem', width: '2.4rem', mr: 1.5 }} fontSize="inherit" />
        {text}
      </StyledTypography>
    );
  }

  const NumberCircle = ({
    text,
    number,
    color
  }: {
    text: string;
    number: number;
    color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  }) => {
    return (
      <StyledTypography>
        <Chip
          color={color}
          component="span"
          size="small"
          sx={{ height: '2rem', width: '2rem', mr: 1.5 }}
          label={number}
        />
        {text}
      </StyledTypography>
    );
  };

  return (
    <Alternaleaf>
      <Grid container spacing={3}>
        <Box>
          <BreadCrumbsContainer item xs={12} marginBottom={4} marginLeft={3} data-testid={'payment-breadcrumb-control'}>
            <StepsBreadCrumb>
              <CheckCircle text="Pre-screen" />
              <CheckCircle text="Booking" />
              <NumberCircle text="Pre-consult" number={3} color={'warning'} />
            </StepsBreadCrumb>
          </BreadCrumbsContainer>
        </Box>

        <Box marginLeft={3}>
          <StyledGridContainer
            container
            rowSpacing={{ xs: 5, sm: 20 }}
            columnSpacing={{ xs: 0, sm: 8 }}
            paddingTop={{ xs: 0, sm: 10 }}
            position={'relative'}
            mt={5}
          >
            <Grid item md={12}>
              <SummaryHeading
                component="h2"
                fontWeight={600}
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                fontSize={'1.6rem'}
              >
                <TaskAltOutlinedIcon color="success" sx={{ fontSize: 40, mr: 2 }} />
                Booking confirmation
              </SummaryHeading>
              <Typography marginBottom={4}>
                Your appointment has been confirmed. A confirmation email has been sent to <strong>{email}</strong>
              </Typography>
              <Typography marginBottom={8}>
                <strong>Appointment Summary:</strong>{' '}
                {bookingStartDateTime &&
                  `Date ${bookingStartDateTime.toLocaleDateString('en-AU')} - Time ${bookingStartDateTime.toLocaleTimeString('en-AU', { hour12: false })}`}
              </Typography>
              <StyledCard>
                <Typography component="h2" fontSize={'1.3rem'} paddingBottom={5} fontWeight={600}>
                  One final step
                </Typography>
                <Typography>
                  Complete the pre-consultation form below to help our nurses and doctors properly assess your
                  condition. This should take around 5 minutes.
                </Typography>
                <PreConsultButton
                  email={email || 'error-referencing-email'}
                  gtmEventId={GoogleAnalyticsEventName.COMPLETE_PRE_CONSULT_SUCCESS}
                />
              </StyledCard>
            </Grid>
          </StyledGridContainer>
          <Alert variant="outlined" severity="info" sx={{ marginTop: 8, marginBottom: 15 }}>
            <strong>Notice Policy:</strong> 24-hour advance notice is required for cancellations or rescheduling. Late
            changes may incur a fee. This supports better access for all patients.
          </Alert>
        </Box>
      </Grid>
    </Alternaleaf>
  );
}
