import { Loader } from '@googlemaps/js-api-loader';

import dataDogLogger from '@/utils/datadogLogger';

function configureGooglePlaces({ googlePlacesApiKey }: { googlePlacesApiKey: string }) {
  const init = async () => {
    try {
      dataDogLogger.info(`Initializing GooglePlaces`);

      const loader = new Loader({
        apiKey: googlePlacesApiKey,
        version: 'weekly'
      });

      await loader.importLibrary('places');
      dataDogLogger.info(`GooglePlaces initialized`);
    } catch (error) {
      if (error instanceof Error) {
        dataDogLogger.error(`Error initializing GooglePlaces`, error);
      }
      dataDogLogger.error(`Unexpected error initializing GooglePlaces`);
    }
  };

  init();
}
export default configureGooglePlaces;
