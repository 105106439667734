import { Box, Divider, Typography } from '@mui/material';

type InstructionProps = {
  title?: string;
  divider?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

export default function Instruction({ title, divider, style, children }: InstructionProps) {
  return (
    <>
      <Box mb="1rem" display="flex" flexDirection="row" style={style}>
        <Typography variant="body1" fontWeight={700}>
          {title}&nbsp;
        </Typography>
        <Typography variant="body1">{children}</Typography>
      </Box>
      {divider && <Divider sx={{ mb: '1rem' }} />}
    </>
  );
}
