type Schema = {
  [key: string]: string | Schema;
};

/**
 * Recursively maps object entries from a single-level (flat) source object to a
 * new object with a shape defined by a schema object.
 *
 * @param flatSource - The single-level source object.
 * @param schema - An object representing the desired shape. Values must either
 * be an object containing further schema or a string matching a key in the
 * source object.
 * @returns - Returns a shaped object with missing keys removed.
 */
export default function mapObject(flatSource: { [key: string]: unknown }, schema: Schema): { [key: string]: unknown } {
  const result: { [key: string]: unknown } = {};

  for (const key in schema) {
    const schemaValue = schema[key];

    if (typeof schemaValue === 'string' && schemaValue in flatSource) {
      result[key] = flatSource[schemaValue];
    } else if (typeof schemaValue === 'object') {
      result[key] = mapObject(flatSource, schemaValue);
    }
  }

  return result;
}
