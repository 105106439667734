import { Checkbox, List, ListItem, ListItemIcon, ListItemText, styled } from '@mui/material';
import type { TransferListItem } from '../TransferList';
import { getItemIds } from '../TransferList.util';

const StyledList = styled(List)({
  height: '70vh',
  overflow: 'scroll',
  overflowX: 'hidden'
});

const StyledListItem = styled(ListItem)(({ theme }) => ({
  cursor: 'pointer',
  marginBottom: 3,
  '.MuiCheckbox-root': {
    padding: 0
  },

  ':has(.Mui-checked)': {
    backgroundColor: theme.palette.grey[300]
  },

  '&:hover': {
    background: theme.palette.grey[300]
  }
}));

const StyledListItemText = styled(ListItemText)({
  '> span': {
    fontSize: '1.3rem',
    lineHeight: 1.43
  }
});

export type TransferListSingleProps = {
  listItems: TransferListItem[];
  checkedItems: TransferListItem[];
  handleItemOnClick: (listItem: TransferListItem) => void;
  ariaLabel: string;
};

export default function TransferListSingle(props: TransferListSingleProps) {
  const { listItems, checkedItems, handleItemOnClick, ariaLabel } = props;

  const itemsOrderedByLabel = listItems?.sort((itemA: TransferListItem, itemB: TransferListItem): number =>
    itemA.label > itemB.label ? 1 : itemB.label > itemA.label ? -1 : 0
  );

  return (
    <StyledList dense role="list" aria-label={ariaLabel}>
      {itemsOrderedByLabel?.map((listItem: TransferListItem) => {
        const isChecked = getItemIds(checkedItems).indexOf(listItem.id) !== -1;
        return (
          <StyledListItem key={listItem.id} role="listitem" onClick={() => handleItemOnClick(listItem)}>
            <ListItemIcon>
              <Checkbox
                checked={isChecked}
                tabIndex={-1}
                disableRipple
                inputProps={{
                  'aria-labelledby': listItem.label
                }}
                data-testid={`checkbox-${listItem.id}-${isChecked}`}
              />
            </ListItemIcon>
            <StyledListItemText id={listItem.label} primary={listItem.label} />
          </StyledListItem>
        );
      })}
      <ListItem />
    </StyledList>
  );
}
