import { Box, styled } from '@mui/material';

export const QuestionWrapper = styled('section')(({ theme }) => ({
  paddingBottom: '6rem',
  width: '100%',

  ['p:has(img)']: {
    textAlign: 'center'
  },

  ['img']: {
    width: '50%'
  },

  [theme.breakpoints.up('sm')]: {
    paddingBottom: 0,

    ['p:has(img)']: {
      textAlign: 'initial'
    },

    ['img']: {
      width: 'auto'
    }
  }
}));

export const AnswersWrapper = styled(Box)({
  marginTop: '1.25rem'
});

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'fixed',
  bottom: 0,
  width: '100%',
  justifyContent: 'flex-end',
  padding: '1rem 1.5rem',
  zIndex: 1,
  left: 0,

  [theme.breakpoints.up('md')]: {
    position: 'inherit',
    padding: '1.5rem 0',
    gap: '1rem',
    justifyContent: 'initial',

    ['button:first-of-type']: {
      marginRight: 0
    }
  }
}));
