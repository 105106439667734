import { Button } from '@montugroup/design-system';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import Instruction from '@/pcp/components/components/Instruction';
import Stages from '@/pcp/components/components/Stages';
import constants from '@/pcp/constants/constants';
import useTracking from '@/pcp/hooks/useTracking';

const journeyCompleteConfig = {
  loginButtonId: 'journey-complete-login-button',
  ga4Events: {
    loginButtonClickEvents: [
      {
        event: 'click_login',
        clickId: 'journey-complete-login-button',
        dlv_click_id: 'journey-complete-login-button',
        event_category: 'button_click'
      }
    ]
  }
};

export default function JourneyComplete() {
  const { loginButtonId, ga4Events } = journeyCompleteConfig;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { sendGa4Events } = useTracking();

  const handleLoginClick = () => {
    sendGa4Events(ga4Events?.loginButtonClickEvents);
  };

  return (
    <>
      <Typography variant="h4" component="h1" color="primary">
        Congrats, you did it!
      </Typography>

      <Stages stage={3} />

      <Typography variant="h2" color="primary" mb="1.5rem" fontSize={isDesktop ? '1.5rem' : '1rem'}>
        Next steps to fulfil your prescription:
      </Typography>

      <Box>
        <Instruction title="Step 1." divider>
          Log in to your account
        </Instruction>
        <Instruction title="Step 2." divider>
          Navigate to refill page to order
        </Instruction>
        <Instruction title="Step 3." style={{ marginBottom: 0 }}>
          Check out and track your order status
        </Instruction>

        <Button
          id={loginButtonId}
          onClick={handleLoginClick}
          href={constants.circuitLoginUrl}
          variant="contained"
          size="large"
          fullWidth={!isDesktop}
          style={{ marginTop: '2.5rem', padding: '0.5rem 1.5rem' }}
        >
          Login
        </Button>
      </Box>
    </>
  );
}
