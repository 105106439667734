import Logger from '@/utils/logger';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';

export type UserContext = {
  kind: string;
  anonymous: boolean;
  [key: string]: string | number | boolean | undefined;
};

type FlagValueResult<T> = {
  flagValue: T | null;
  error?: Error;
  loading: boolean;
};

export function useFeatureFlagValueForUser<T = boolean | string | number>(
  flagName: string,
  userContext: UserContext
): FlagValueResult<T> {
  const ldClient = useLDClient(); // Hook call inside a custom hook
  const [flagValue, setFlagValue] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>(undefined);
  const logger = new Logger('useFeatureFlagValueForUser');

  useEffect(() => {
    const fetchFlagValue = async () => {
      if (!ldClient) {
        const err = new Error('LaunchDarkly client not initialized.');
        logger.error(err.message);
        setError(err);
        setLoading(false);
        return;
      }

      try {
        await ldClient.identify(userContext);
        const flagValue = ldClient.variation(flagName, null) as T;
        setFlagValue(flagValue);
      } catch (err: unknown) {
        logger.error('Identify or flag retrieval failed:', err);

        const typedError = err instanceof Error ? err : new Error(String(err));
        setError(typedError);
      } finally {
        setLoading(false);
      }
    };

    fetchFlagValue();
  }, [ldClient, flagName, userContext]);

  return { flagValue, error, loading };
}
