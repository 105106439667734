import { Button } from '@montugroup/design-system';
import { BrandThemeProvider } from '@montugroup/themes';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, Link, Typography } from '@mui/material';

function ErrorFallback() {
  return (
    <BrandThemeProvider>
      <Box role="alert" display="flex" justifyContent="center" alignItems="center" mt={30} p={2}>
        <Box textAlign="center">
          <ErrorIcon color="error" sx={{ fontSize: 60 }} />
          <Typography variant="h4" component="h1">
            Something went wrong
          </Typography>
          <Typography variant="body1" my={2}>
            There was a problem processing the request. Please try again.
          </Typography>
          <Typography variant="body1">
            If the problem persists, please contact us at <Link href="tel:1800 844 920">1800 844 920</Link>
          </Typography>
          <Box mt={10}>
            <Button onClick={() => window.location.reload()}>Reload</Button>
          </Box>
        </Box>
      </Box>
    </BrandThemeProvider>
  );
}

export default ErrorFallback;
