import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Box, Button, Grid, styled, Tooltip } from '@mui/material';
import type { TransferListItem } from '../TransferList';
import { findIntersection } from '../TransferList.util';

export type TransferListButtonsProps = {
  checkedItems: TransferListItem[];
  leftSideItems: TransferListItem[];
  rightSideItems: TransferListItem[];
  handleMoveAllRightOnClick: () => void;
  handleMoveAllLeftOnClick: () => void;
  handleMoveCheckedLeftOnClick: () => void;
  handleMoveCheckedRightOnClick: () => void;
};

const StyledTooltip = styled(Tooltip)({
  ':has(.Mui-disabled)': {
    cursor: 'not-allowed'
  }
});

const StyledButton = styled(Button)(() => ({
  padding: 15,
  marginBottom: 15,
  minWidth: 'inherit',
  '&:hover': {
    padding: '15px !important'
  }
}));

export default function TransferListButtons(props: TransferListButtonsProps) {
  const {
    checkedItems,
    leftSideItems,
    rightSideItems,
    handleMoveAllLeftOnClick,
    handleMoveAllRightOnClick,
    handleMoveCheckedLeftOnClick,
    handleMoveCheckedRightOnClick
  } = props;

  const leftChecked = findIntersection(checkedItems, leftSideItems);
  const rightChecked = findIntersection(checkedItems, rightSideItems);

  return (
    <Grid container direction="column" alignItems="center">
      <StyledTooltip title={'Move all items to the right'} placement="right">
        <Box component="span" marginBottom={10}>
          <StyledButton
            variant="outlined"
            size="small"
            onClick={handleMoveAllRightOnClick}
            disabled={leftSideItems?.length === 0}
            aria-label="move all right"
          >
            <KeyboardDoubleArrowRightIcon fontSize={'large'} />
          </StyledButton>
        </Box>
      </StyledTooltip>
      <StyledTooltip title={'Move checked items to the right'} placement="right">
        <span>
          <StyledButton
            variant="outlined"
            size="small"
            onClick={handleMoveCheckedRightOnClick}
            disabled={leftChecked?.length === 0}
            aria-label="move selected right"
          >
            <KeyboardArrowRightIcon fontSize={'large'} />
          </StyledButton>
        </span>
      </StyledTooltip>
      <StyledTooltip title={'Move checked items to the left'} placement="right">
        <span>
          <StyledButton
            variant="outlined"
            size="small"
            onClick={handleMoveCheckedLeftOnClick}
            disabled={rightChecked?.length === 0}
            aria-label="move selected left"
          >
            <KeyboardArrowLeftIcon fontSize={'large'} />
          </StyledButton>
        </span>
      </StyledTooltip>
      <StyledTooltip title={'Move all items to the left'} placement="right">
        <Box component="span" marginTop={10}>
          <StyledButton
            variant="outlined"
            size="large"
            onClick={handleMoveAllLeftOnClick}
            disabled={rightSideItems?.length === 0}
            aria-label="move all left"
          >
            <KeyboardDoubleArrowLeftIcon fontSize={'large'} />
          </StyledButton>
        </Box>
      </StyledTooltip>
    </Grid>
  );
}
