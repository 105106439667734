import type { ContainerProps } from '@mui/material';
import { Box, Container, styled, Typography } from '@mui/material';

type FooterProps = {
  containerMaxWidth?: ContainerProps['maxWidth'] | number;
};

const FooterWrapper = styled(Box)(({ theme: t }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '2.5rem',
  width: '100%',
  background: t.palette.primary.main,
  color: t.palette.primary.contrastText
}));

export default function Footer({ containerMaxWidth = 'md' }: FooterProps) {
  return (
    <FooterWrapper>
      <Container
        maxWidth={typeof containerMaxWidth === 'string' ? containerMaxWidth : undefined}
        sx={{ maxWidth: typeof containerMaxWidth === 'number' ? containerMaxWidth : undefined }}
      >
        <Typography variant="body2">&copy; {new Date().getFullYear()} by Alternaleaf. All rights reserved.</Typography>
      </Container>
    </FooterWrapper>
  );
}
