import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext } from 'react';

import { FFLoadingStateContext } from '@/components/featureFlags/FFLoadingStateProvider';
import type ffDefaults from '@/constants/ffDefaults';

const useFeatureFlags = () => {
  const flags = { ...useFlags<typeof ffDefaults>() };
  const { loading } = useContext(FFLoadingStateContext);

  return { flags, loading };
};

export default useFeatureFlags;
