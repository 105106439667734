import { useCallback } from 'react';
import TagManager from 'react-gtm-module';

import Logger from '@/utils/logger';

const logger = new Logger('useGoogleAnalytics.ts');

const useGoogleTagManager = () => {
  const sendGoogleAnalyticsEvent = useCallback((eventName: string, params: Record<string, string>) => {
    logger.debug('Sending Google Analytics event', eventName, params);

    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
        ...params
      }
    });
  }, []);

  return { sendGoogleAnalyticsEvent };
};

export default useGoogleTagManager;
