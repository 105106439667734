import { Box, styled } from '@mui/material';

export const PageWrapper = styled(Box)(({ theme: t }) => ({
  backgroundImage: 'url(/assets/DesktopMotif.svg)',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '30% top',
  backgroundSize: 'cover',
  height: '100vh',
  width: '100%',
  padding: '5rem 0 0',

  [t.breakpoints.up('md')]: {
    backgroundPosition: 'center top',
    paddingTop: '8rem'
  }
}));

export const WithPageWrapper = (component: React.ReactElement, wrapped = false) => {
  if (wrapped) {
    return <PageWrapper>{component}</PageWrapper>;
  }
  return component;
};
