import type { ContainerProps } from '@mui/material';
import { Box, Container, styled } from '@mui/material';

import Footer from '@/pcp/components/components/Footer';
import FullSpinner from '@/pcp/components/components/FullSpinner';
import Header from '@/pcp/components/components/Header';

type LayoutProps = {
  showHeader?: boolean;
  showFooter?: boolean;
  headerMaxWidth?: ContainerProps['maxWidth'];
  contentMaxWidth?: ContainerProps['maxWidth'];
  footerMaxWidth?: ContainerProps['maxWidth'];
  loading?: boolean;
  children?: React.ReactNode;
};

const LayoutWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100svh',

  // defining container gutters so they can be overridden without hardcoded values
  ['--container-gutter']: '1rem',
  ['.MuiContainer-root']: {
    paddingLeft: 'var(--container-gutter)',
    paddingRight: 'var(--container-gutter)'
  },

  // @deprecated - needs fixing in design system before can be removed
  // issue: large outlined buttons add 1px of padding when hovered (8px 22px)
  ['.MuiButton-sizeLarge.MuiButton-outlined:hover']: {
    padding: '7px 21px !important'
  },

  // @deprecated - needs fixing in design system before can be removed
  // issue: default line height is too squished
  ['.MuiTypography-body1']: {
    lineHeight: '1.5rem'
  }
});

const Content = styled(Container)(({ theme: t }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '0.5rem',
  paddingBottom: '3rem',

  [t.breakpoints.up('md')]: {
    paddingTop: '5rem',
    paddingBottom: '5rem'
  }
}));

export default function Layout({
  showHeader = true,
  showFooter = true,
  headerMaxWidth = 'md',
  contentMaxWidth = 'md',
  footerMaxWidth = 'md',
  loading,
  children
}: LayoutProps) {
  return (
    <LayoutWrapper>
      {showHeader && <Header containerMaxWidth={headerMaxWidth} />}

      <Content maxWidth={contentMaxWidth}>
        {loading && <FullSpinner />}
        <main style={{ display: loading ? 'none' : 'block' }}>{children}</main>
      </Content>

      {showFooter && <Footer containerMaxWidth={footerMaxWidth} />}
    </LayoutWrapper>
  );
}
