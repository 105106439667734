import { Autocomplete, FormControl, ListItem, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';

import { FF_ENABLE_GOOGLE_PLACES } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import dataDogLogger from '@/utils/datadogLogger';

import { FormElements } from '../../FormType';

import { PARCEL_LOCKER_ERROR_MESSAGE, validateParcelLocker } from '@montu-web/utilities';
import Input from './Input';

export interface LocationInputProps {
  name: string;
  label: string;
}

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}
type AddressComponents = {
  long_name: string;
  short_name: string;
  types: string[];
};

type PlaceDetails = {
  address_components: AddressComponents[];
};

export const LocationInput = ({ name, label }: LocationInputProps) => {
  const { flags } = useFeatureFlags();
  const isGooglePlacesEnabled = flags[FF_ENABLE_GOOGLE_PLACES];
  const { control, setValue: setFormValue, setError, clearErrors, trigger } = useFormContext();
  const [selectedAddress, setSelectedAddress] = useState<PlaceDetails>();

  const {
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    debounce: 300,
    callbackName: 'initMap',
    requestOptions: {
      componentRestrictions: { country: 'au' },
      types: ['address']
    }
  });

  const options = !data.length ? { freeSolo: true, autoSelect: true } : {};

  const validateAddress = (result: PlaceDetails) => {
    if (!result.address_components.find((c) => c.types.includes('street_number'))) {
      setError(name, { type: 'custom', message: 'Invalid address, please type street number.' });
      dataDogLogger.error('Invalid address, please type street number.');
    } else {
      clearErrors(['address.suburb', 'address.state', 'address.postcode']);
    }
  };

  const fillInAddress = (result: PlaceDetails) => {
    for (const component of result.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case 'locality':
          setFormValue('address.suburb', component.long_name);
          break;

        case 'administrative_area_level_1':
          setFormValue('address.state', component.short_name);
          break;

        case 'postal_code':
          setFormValue('address.postcode', component.long_name);
          break;
      }
    }
  };

  useEffect(() => {
    if (selectedAddress) {
      validateAddress(selectedAddress);
      fillInAddress(selectedAddress);
    }
  }, [selectedAddress]);

  const resetForm = () => {
    clearSuggestions();
    setValue('');
    setFormValue('address.suburb', '');
    setFormValue('address.state', '');
    setFormValue('address.postcode', '');
    trigger(name);
  };

  const handleSelect = async (addressResult: PlaceType) => {
    if (!addressResult) {
      resetForm();
      return;
    }
    const address = addressResult.description;
    dataDogLogger.info('Google Geocode API request.');

    if (address) {
      try {
        const results = await getGeocode({ address });
        if (results.length > 0) {
          setSelectedAddress(results[0]);
          dataDogLogger.info('Google Geocode API request successful.');
        }
        clearSuggestions();
      } catch (error) {
        if (error instanceof Error) {
          dataDogLogger.error('Error during Google Geocode API request', error);
        }
        dataDogLogger.error('Unexpected error during Google Geocode API request');
      }
    }
  };

  return (
    <FormControl sx={{ my: 2 }} fullWidth>
      <Controller
        rules={{
          required: 'This field is required',
          validate: {
            isParcelLocker: (value) => validateParcelLocker(value) || PARCEL_LOCKER_ERROR_MESSAGE
          }
        }}
        control={control}
        defaultValue=""
        name={name}
        render={({ field: { onChange, value }, fieldState: { isDirty, invalid, error } }) =>
          isGooglePlacesEnabled ? (
            <Autocomplete
              {...options}
              className="combobox-input"
              disabled={!ready}
              value={value}
              filterOptions={(x) => x}
              options={status === 'OK' ? data : []}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
              isOptionEqualToValue={(option, value) => option.structured_formatting.main_text === value}
              onChange={(_event, newValue) => {
                handleSelect(newValue);
                onChange(options.freeSolo ? newValue : newValue?.structured_formatting?.main_text);
                clearErrors(name);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  variant="filled"
                  fullWidth
                  onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                  }}
                  color={isDirty && !invalid ? 'success' : 'primary'}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
              renderOption={(props, option) => (
                <ListItem {...props} key={option.place_id} data-testid="autocomplete-item-id">
                  {option.description}
                </ListItem>
              )}
            />
          ) : (
            <Input type={FormElements.input} name={name} label={label} />
          )
        }
      />
    </FormControl>
  );
};
