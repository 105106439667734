import { Box, Divider, List, ListItem, styled } from '@mui/material';
import { Link, Outlet } from 'react-router-dom';

import constants from '@/constants/constants';
import pcpConstants from '@/pcp/constants/constants';

import { PageWrapper } from './components/Branding';

const ChildListItem = styled(ListItem)(({ theme: t }) => ({
  marginLeft: t.spacing(6)
}));

export default function HomeLinks() {
  return (
    <PageWrapper>
      <Box display="flex" justifyContent="center" marginTop="2rem">
        <List>
          <ListItem>
            <Link to="/pre-screening">Pre-Screening form</Link>
          </ListItem>
          <ChildListItem>
            <Link to="/pre-screening?cal=true">Nurse booking calendar</Link>
          </ChildListItem>

          <Divider sx={{ my: 4 }} />

          <ListItem sx={{ fontWeight: 'bold' }}>
            <Link to={`/${pcpConstants.frontendPath}?test`}>Pre-Consultation Portal (PCP)</Link>
          </ListItem>
          <ChildListItem>
            <Link to="/pre-consultation">Pre-Consultation form</Link>
          </ChildListItem>
          <ChildListItem>
            <Link to={`/${constants.qualityOfLifeForm.frontendPath}`}>Quality-of-Life form</Link>
          </ChildListItem>
        </List>
        <Outlet />
      </Box>
    </PageWrapper>
  );
}
