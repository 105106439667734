export const hasValidMedicareChecksum = (medicareNumber: string) => {
  if (!/^[2-6]\d{9}$/.test(medicareNumber)) {
    return false;
  }
  const expectedChecksum = [1, 3, 7, 9, 1, 3, 7, 9].reduce(
    (acc, w, i) => (acc + w * Number(medicareNumber[i])) % 10,
    0
  );
  return expectedChecksum === Number(medicareNumber[8]);
};
