export default function Wifi() {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_802_3226)">
        <path
          d="M15 26.25C14.125 26.25 13.3854 25.9479 12.7812 25.3438C12.1771 24.7396 11.875 24 11.875 23.125C11.875 22.25 12.1771 21.5104 12.7812 20.9062C13.3854 20.3021 14.125 20 15 20C15.875 20 16.6146 20.3021 17.2188 20.9062C17.8229 21.5104 18.125 22.25 18.125 23.125C18.125 24 17.8229 24.7396 17.2188 25.3438C16.6146 25.9479 15.875 26.25 15 26.25ZM7.9375 19.1875L5.3125 16.5C6.54167 15.2708 7.98438 14.2969 9.64062 13.5781C11.2969 12.8594 13.0833 12.5 15 12.5C16.9167 12.5 18.7031 12.8646 20.3594 13.5938C22.0156 14.3229 23.4583 15.3125 24.6875 16.5625L22.0625 19.1875C21.1458 18.2708 20.0833 17.5521 18.875 17.0312C17.6667 16.5104 16.375 16.25 15 16.25C13.625 16.25 12.3333 16.5104 11.125 17.0312C9.91667 17.5521 8.85417 18.2708 7.9375 19.1875ZM2.625 13.875L0 11.25C1.91667 9.29167 4.15625 7.76042 6.71875 6.65625C9.28125 5.55208 12.0417 5 15 5C17.9583 5 20.7188 5.55208 23.2812 6.65625C25.8438 7.76042 28.0833 9.29167 30 11.25L27.375 13.875C25.7708 12.2708 23.9115 11.0156 21.7969 10.1094C19.6823 9.20312 17.4167 8.75 15 8.75C12.5833 8.75 10.3177 9.20312 8.20312 10.1094C6.08854 11.0156 4.22917 12.2708 2.625 13.875Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_802_3226">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
