import { datadogLogs } from '@datadog/browser-logs';

const dataDogLogger = {
  info: (message: string, params?: Record<string, any>) => {
    datadogLogs.logger.info(message, params);
  },
  warn: (message: string, params?: Record<string, any>) => {
    datadogLogs.logger.warn(message, params);
  },
  error: (message: string, params?: Record<string, any>) => {
    datadogLogs.logger.error(message, params);
  }
};

export default dataDogLogger;
