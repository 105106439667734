import { Typography } from '@mui/material';

import Stages from '@/pcp/components/components/Stages';

export default function ConsultsComplete() {
  return (
    <>
      <Typography variant="h4" component="h1" color="primary">
        Consultations complete!
      </Typography>

      <Stages stage={3} />
    </>
  );
}
