import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, Button, Collapse, TextField, Typography, styled } from '@mui/material';
import type { KeyboardEvent } from 'react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { FF_ENABLE_PROMOTION_HELPER_MESSAGE } from '@/constants/featureFlags';
import useDiscountCode, { DiscountCodeStatus } from '@/hooks/useDiscountCode';
import useFeatureFlags from '@/hooks/useFeatureFlags';

const Wrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '28.125rem'
  }
}));

const DiscountCode = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '1rem',
  marginBottom: '2rem',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row'
  }
}));

const ApplyButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    minWidth: 'auto'
  }
}));

const termsAndConditionsLink = 'https://www.alternaleaf.com.au/promotion-terms';

function discountCodeInputFeedback(status?: DiscountCodeStatus, isPromotionHelperMessageEnabled?: boolean) {
  switch (status) {
    case DiscountCodeStatus.valid:
      return {
        InputProps: {
          endAdornment: <CheckCircleIcon fontSize="large" color="success" />
        },
        helperText: isPromotionHelperMessageEnabled ? (
          <Typography component="span">
            <strong>Your promo code has been applied, but you are eligible for a better offer. </strong> Your discounted
            consult price will be reflected on the consultation payment page.{' '}
            <a href={termsAndConditionsLink} target="_blank" rel="noopener noreferrer" style={{ color: '#1E0421' }}>
              View full offer terms and conditions.
            </a>
          </Typography>
        ) : (
          <Typography component="span">
            <strong>Congratulations!</strong> Your coupon code has been successfully applied. Your discount will be
            applied once you reach the consultation payment page.{' '}
            <a href={termsAndConditionsLink} target="_blank" rel="noopener noreferrer" style={{ color: '#1E0421' }}>
              View full offer terms and conditions.
            </a>
          </Typography>
        )
      };
    case DiscountCodeStatus.invalid:
    case DiscountCodeStatus.expired:
      return {
        InputProps: {
          endAdornment: <ErrorIcon fontSize="large" color="error" />
        },
        helperText: isPromotionHelperMessageEnabled ? (
          <Typography component="span">
            <strong>
              The promo code you have entered is not valid or has expired, but you are eligible for a better offer.{' '}
            </strong>
            Your discounted consult price will be reflected on the consultation payment page.{' '}
            <a href={termsAndConditionsLink} target="_blank" rel="noopener noreferrer" style={{ color: '#1E0421' }}>
              View full offer terms and conditions.
            </a>
          </Typography>
        ) : (
          <Typography component="span">
            The promo code you have entered is not valid or has expired. Please double-check your code and try again.{' '}
            <a href={termsAndConditionsLink} target="_blank" rel="noopener noreferrer" style={{ color: '#1E0421' }}>
              View full offer terms and conditions.
            </a>
          </Typography>
        )
      };
    default:
      return null;
  }
}

export default function AddPromoCode() {
  const { flags } = useFeatureFlags();
  const isPromotionHelperMessageEnabled = flags[FF_ENABLE_PROMOTION_HELPER_MESSAGE];

  const [searchParams, setSearchParams] = useSearchParams();
  const promocode = searchParams.get('promocode') || '';

  const { isLoading, data, refetch } = useDiscountCode(promocode || '');
  const [discountCode, setDiscountCode] = useState<string>(promocode || '');
  const [expanded, setExpanded] = useState(false);

  const discountStatus = data?.status;
  const discountCodeValue = data?.discountCode;

  useEffect(() => {
    if (discountStatus === DiscountCodeStatus.valid && discountCodeValue) {
      setExpanded(true);
      searchParams.set('promocode', discountCodeValue);
      setSearchParams(searchParams);
      return;
    }

    searchParams.delete('promocode');
    setSearchParams(searchParams);
  }, [discountCodeValue, discountStatus, searchParams, setSearchParams]);

  const handleDiscountApply = () => {
    if (discountCode) {
      refetch(discountCode);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    if (e.key == 'Enter' && target.value) {
      refetch(e.currentTarget.value);
    }
  };

  const handleExpandToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Wrapper>
      <Typography
        variant="body1"
        component="h2"
        fontWeight={600}
        marginBottom={8}
        marginTop={8}
        sx={{ cursor: 'pointer', textDecoration: 'underline' }}
        onClick={handleExpandToggle}
        id="have-promo-code" // do not change this id, it is used for tracking purposes.
      >
        I have a discount code
      </Typography>
      <Collapse in={expanded}>
        <DiscountCode>
          <TextField
            id="input-promo-code"
            type="text"
            variant="filled"
            fullWidth
            placeholder="Enter code"
            {...discountCodeInputFeedback(discountStatus, isPromotionHelperMessageEnabled)}
            disabled={isLoading}
            value={discountCode}
            onKeyDown={handleKeyDown}
            onChange={(e) => setDiscountCode(e.target.value.toLocaleUpperCase())}
          />
          <Box paddingTop={2}>
            <ApplyButton disabled={isLoading} variant="contained" onClick={handleDiscountApply} id="apply-promo-code">
              Apply
            </ApplyButton>
          </Box>
        </DiscountCode>
      </Collapse>
      <Typography sx={{ color: 'grey', fontSize: '0.90rem' }}>No code? Proceed to booking</Typography>
    </Wrapper>
  );
}
