import type { CalComNameInput } from '@/constants/types';

interface CalComFormattedName {
  firstName?: string;
  lastName?: string;
}

const splitName = (name: string): CalComFormattedName => {
  const names = name.split(' ');

  return {
    firstName: names[0] as string,
    lastName: (names.slice(1) as string[]).join(' ')
  };
};

/***
 * Accepts a patients name in both input types from CalCom.
 * This will be either a single string, or an object for first and last name.
 *
 * Returns a patients name in a common format.
 */
export const getCalComNameInput = (name?: CalComNameInput): CalComFormattedName => {
  const patientName = typeof name === 'string' ? splitName(name) : name;

  return {
    firstName: patientName?.firstName?.trim(),
    lastName: patientName?.lastName?.trim()
  };
};
