export default function Question() {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 27.5V22.125C6.3125 21.0417 5.39062 19.776 4.73438 18.3281C4.07812 16.8802 3.75 15.3542 3.75 13.75C3.75 10.625 4.84375 7.96875 7.03125 5.78125C9.21875 3.59375 11.875 2.5 15 2.5C17.6042 2.5 19.9115 3.26562 21.9219 4.79688C23.9323 6.32812 25.2396 8.32292 25.8438 10.7812L27.4688 17.1875C27.5729 17.5833 27.5 17.9427 27.25 18.2656C27 18.5885 26.6667 18.75 26.25 18.75H23.75V22.5C23.75 23.1875 23.5052 23.776 23.0156 24.2656C22.526 24.7552 21.9375 25 21.25 25H18.75V27.5H16.25V22.5H21.25V16.25H24.625L23.4375 11.4062C22.9583 9.51042 21.9375 7.96875 20.375 6.78125C18.8125 5.59375 17.0208 5 15 5C12.5833 5 10.5208 5.84375 8.8125 7.53125C7.10417 9.21875 6.25 11.2708 6.25 13.6875C6.25 14.9375 6.50521 16.125 7.01562 17.25C7.52604 18.375 8.25 19.375 9.1875 20.25L10 21V27.5H7.5ZM15 20C15.3542 20 15.651 19.8802 15.8906 19.6406C16.1302 19.401 16.25 19.1042 16.25 18.75C16.25 18.3958 16.1302 18.099 15.8906 17.8594C15.651 17.6198 15.3542 17.5 15 17.5C14.6458 17.5 14.349 17.6198 14.1094 17.8594C13.8698 18.099 13.75 18.3958 13.75 18.75C13.75 19.1042 13.8698 19.401 14.1094 19.6406C14.349 19.8802 14.6458 20 15 20ZM14.0625 16H15.9688C15.9688 15.4792 16.0365 15.0573 16.1719 14.7344C16.3073 14.4115 16.5833 14.0208 17 13.5625C17.375 13.1458 17.7396 12.724 18.0938 12.2969C18.4479 11.8698 18.625 11.3125 18.625 10.625C18.625 9.75 18.2865 9.01042 17.6094 8.40625C16.9323 7.80208 16.0938 7.5 15.0938 7.5C14.2604 7.5 13.5052 7.73958 12.8281 8.21875C12.151 8.69792 11.6771 9.32292 11.4062 10.0938L13.125 10.8125C13.2708 10.3542 13.526 9.98438 13.8906 9.70312C14.2552 9.42188 14.6562 9.28125 15.0938 9.28125C15.5521 9.28125 15.9323 9.40625 16.2344 9.65625C16.5365 9.90625 16.6875 10.2292 16.6875 10.625C16.6875 11.0625 16.5573 11.4531 16.2969 11.7969C16.0365 12.1406 15.7292 12.4896 15.375 12.8438C14.9583 13.2812 14.6354 13.7188 14.4062 14.1562C14.1771 14.5938 14.0625 15.2083 14.0625 16Z"
        fill="currentColor"
      />
    </svg>
  );
}
