import { TextField } from '@mui/material';
import { forwardRef, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import type { PatternFormatProps } from 'react-number-format';
import { PatternFormat } from 'react-number-format';

import type { InputElementProps } from '@/components/form-builder/components/input/types';

const NumericFormatCustom = forwardRef<PatternFormatProps, { format: string }>(
  function NumericFormatCustom(props, ref) {
    return <PatternFormat {...props} getInputRef={ref} valueIsNumericString />;
  }
);

const NumberTextField = ({ type, onChange, name, value, inputProps, ...restInputProps }: InputElementProps) => {
  const { trigger, setError } = useFormContext();

  const customInput = inputProps?.format
    ? { InputProps: { inputComponent: NumericFormatCustom as any } }
    : { type, inputProps: { min: 0, ...inputProps } };

  const onChangeWrapper = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      if (customInput?.type) {
        onChange(parseInt(target.value.toString(), 10));
      } else {
        // always return number as string
        const trimmedNumberStr = target.value.replace(/\s+/g, '');
        onChange(trimmedNumberStr);
      }
      trigger(name);
    },
    [customInput?.type, name, onChange, trigger]
  );
  const onBlur = useCallback(() => {
    if (inputProps?.format && inputProps?.format.replace(/\s/g, '').length > value.toString().trim().length) {
      setError(name, { type: 'custom', message: 'invalid format' });
    } else if (Number(value) < 0) {
      setError(name, { type: 'custom', message: 'invalid value' });
    }
  }, [inputProps?.format, name, setError, value]);

  return (
    <TextField
      onChange={onChangeWrapper}
      onBlur={onBlur}
      value={value}
      inputProps={inputProps}
      {...restInputProps}
      {...customInput}
    />
  );
};
export default NumberTextField;
