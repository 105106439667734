import { useContext } from 'react';

import QualityOfLifeForm from '@/pages/QualityOfLifeForm';
import Dialog from '@/pcp/components/components/Dialog';
import Step from '@/pcp/components/components/Step';
import { PCPStepContext } from '@/pcp/components/components/StepContextWrapper';
import useTracking from '@/pcp/hooks/useTracking';
import { PCPDataContext } from '@/pcp/pages/PCPortal';
import { addSubmittedForm } from '@/pcp/utils/helpers';

export default function QolForm() {
  const { token, userData, fetchUserData } = useContext(PCPDataContext);
  const { modalOpen, setModalOpen, stepId, url, isLink, ga4Events } = useContext(PCPStepContext);

  const { sendGa4Events } = useTracking();

  const handleButtonClick = (mouseEvent: React.MouseEvent<HTMLButtonElement>) => {
    sendGa4Events(ga4Events?.buttonClickEvents);

    if (!isLink) {
      if (url && mouseEvent.ctrlKey) {
        window.open(url, '_blank');
        return;
      }
      setModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    fetchUserData({ updateLoadingState: false });
  };

  const handleFormSubmit = (submitError: unknown) => {
    if (submitError) {
      return;
    }

    addSubmittedForm(stepId);
    sendGa4Events(ga4Events?.formCompleteEvents);
    fetchUserData({ updateLoadingState: false });
  };

  return (
    <>
      <Step onButtonClick={handleButtonClick} divider />

      <Dialog open={modalOpen} onCloseButtonClick={handleModalClose}>
        <QualityOfLifeForm
          pageWrapper={false}
          urlParams={userData?.prefillFormData}
          userToken={token}
          onSubmit={(_, submitError) => handleFormSubmit(submitError)}
        />
      </Dialog>
    </>
  );
}
