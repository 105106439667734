import { createContext } from 'react';

import TestingMenu from '@/pcp/components/components/TestingMenu';
import Layout from '@/pcp/components/Layout';
import type { PCPDataContextType, PCPErrorType } from '@/pcp/constants/types';
import { pcpErrorTypeStrings } from '@/pcp/constants/types';
import Error from '@/pcp/content/Error';
import ConsultsComplete from '@/pcp/content/stages/ConsultsComplete';
import JourneyComplete from '@/pcp/content/stages/JourneyComplete';
import PreDoctor from '@/pcp/content/stages/PreDoctor';
import PreNurse from '@/pcp/content/stages/PreNurse';
import useUserData from '@/pcp/hooks/useUserData';
import { getPage } from '@/pcp/utils/helpers';

export const PCPDataContext = createContext<PCPDataContextType>({} as PCPDataContextType);

export default function PCPortal() {
  const dataContextValue = useUserData();
  const { pcpEnabled, testing, demoTesting, testPage, userData, userDataLoading, error } = dataContextValue;

  const page = getPage(userData, error);
  const targetPage = testing && demoTesting ? testPage : page;
  const isErrorTargetPage = (pcpErrorTypeStrings as readonly string[]).includes(targetPage);

  const getContent = () => {
    if (!pcpEnabled) {
      return <Error type="404" />;
    }

    if (isErrorTargetPage) {
      return <Error type={targetPage as PCPErrorType} />;
    }

    switch (targetPage) {
      case 'blank':
        return null;
      case 'pre_nurse':
        return <PreNurse />;
      case 'pre_doctor':
        return <PreDoctor />;
      case 'consults_complete':
        return <ConsultsComplete />;
      case 'journey_complete':
        return <JourneyComplete />;
      default:
        return null;
    }
  };

  return (
    <PCPDataContext.Provider value={dataContextValue}>
      <Layout loading={userDataLoading}>{getContent()}</Layout>

      {testing && <TestingMenu />}
    </PCPDataContext.Provider>
  );
}
