import type { LDFlagSet } from 'launchdarkly-js-sdk-common';

import {
  FF_DISABLE_CONSULTATION_BUTTONS,
  FF_ENABLE_DISCOUNT_CODE,
  FF_ENABLE_GOOGLE_PLACES,
  FF_ENABLE_PROMOTION_HELPER_MESSAGE,
  FF_ENABLE_SNOWPLOW,
  FF_MEDICARE_CHECKSUM,
  FF_PAYMENT_SKIPPED_FLOW_EXPERIMENT,
  FF_PPP_ENABLED,
  FF_PPP_TESTING
} from '@/constants/featureFlags';

const ffDefaults: LDFlagSet = {
  [FF_DISABLE_CONSULTATION_BUTTONS]: false,
  [FF_ENABLE_DISCOUNT_CODE]: false,
  [FF_ENABLE_GOOGLE_PLACES]: false,
  [FF_ENABLE_PROMOTION_HELPER_MESSAGE]: false,
  [FF_ENABLE_SNOWPLOW]: false,
  [FF_MEDICARE_CHECKSUM]: false,
  [FF_PPP_ENABLED]: false,
  [FF_PPP_TESTING]: false,
  [FF_PAYMENT_SKIPPED_FLOW_EXPERIMENT]: 'off'
};

export default ffDefaults;
