import { Box } from '@mui/material';

export default function Logo({ width, height }: { width?: number; height?: number }) {
  return (
    <Box sx={{ color: (theme) => (theme.palette?.colorScheme === 'dark' ? 'common.white' : 'primary.main') }}>
      <svg width={width || 199} height={height} viewBox="0 0 199 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M34.7158 0.736084H30.5508V29.2607H34.7158V0.736084Z" fill="currentColor" />
        <path
          d="M46.3009 3.07434H42.1747V8.81807H38.6445V12.5437H42.1747V29.2607H46.3009V12.5437H51.7387V8.81807H46.3009V3.07434Z"
          fill="currentColor"
        />
        <path
          d="M63.5601 8.58203C57.928 8.58203 53.7598 13.2165 53.7598 19.041C53.7598 24.8656 57.847 29.5001 63.7577 29.5001C70.0667 29.5001 72.4051 24.6651 72.7225 22.7247H68.4765C68.0782 23.9116 66.611 25.7744 63.7545 25.7744C60.2631 25.7744 58.3587 23.3585 58.0025 20.3056H72.9978C73.0366 19.869 73.0755 19.3548 73.0755 18.8017C73.0755 13.2165 69.0303 8.58203 63.5537 8.58203H63.5601ZM58.1256 17.217C58.6405 14.4454 60.506 12.3045 63.5601 12.3045C66.6143 12.3045 68.4409 14.4842 68.8749 17.217H58.1223H58.1256Z"
          fill="currentColor"
        />
        <path
          d="M77.4837 15.2376V29.3027H81.5289V15.2376C81.5289 13.8114 82.6786 12.5437 84.1458 12.5437H87.676V8.81799H83.9061C80.3759 8.81799 77.4805 11.6705 77.4805 15.2344L77.4837 15.2376Z"
          fill="currentColor"
        />
        <path
          d="M98.9851 8.58203H98.9462C93.9489 8.58203 90.2988 12.3853 90.2988 17.9318V29.2607H94.344V17.9318C94.344 14.6847 96.0119 12.6214 98.9462 12.6214H98.9851C101.919 12.6214 103.548 14.6815 103.548 17.9318V29.2607H107.594V17.9318C107.594 12.3853 103.944 8.58203 98.9851 8.58203Z"
          fill="currentColor"
        />
        <path
          d="M120.847 8.58203C115.807 8.58203 112.675 11.6318 112.358 15.5547H116.643C116.921 12.9416 118.984 12.1072 120.967 12.1072C123.149 12.0684 125.212 13.0968 125.212 16.1077V17.0974H121.048C114.738 17.0974 111.963 19.5941 111.963 23.5558C111.963 26.6444 114.224 29.5001 118.706 29.5001C121.562 29.5001 124.26 27.9154 125.212 26.5667V29.2607H129.261V16.3859C129.261 11.6318 126.443 8.58203 120.85 8.58203H120.847ZM125.051 20.5869C125.051 24.6683 122.233 26.3307 119.577 26.3307C117.278 26.3307 116.244 25.1017 116.244 23.4394C116.244 21.3405 118.149 20.3088 121.005 20.27L125.051 20.2312V20.5869Z"
          fill="currentColor"
        />
        <path d="M139.093 0.736084H134.928V29.2607H139.093V0.736084Z" fill="currentColor" />
        <path
          d="M153.337 8.58203C147.702 8.58203 143.537 13.2165 143.537 19.041C143.537 24.8656 147.624 29.5001 153.535 29.5001C159.844 29.5001 162.182 24.6651 162.5 22.7247H158.254C157.855 23.9116 156.388 25.7744 153.532 25.7744C150.04 25.7744 148.136 23.3585 147.78 20.3056H162.775C162.814 19.869 162.856 19.3548 162.856 18.8017C162.856 13.2165 158.808 8.58203 153.334 8.58203H153.337ZM147.903 17.217C148.418 14.4454 150.283 12.3045 153.337 12.3045C156.392 12.3045 158.218 14.4842 158.652 17.217H147.9H147.903Z"
          fill="currentColor"
        />
        <path
          d="M174.921 8.58203C169.881 8.58203 166.75 11.6318 166.432 15.5547H170.717C170.996 12.9416 173.059 12.1072 175.041 12.1072C177.224 12.0684 179.287 13.0968 179.287 16.1077V17.0974H175.122C168.813 17.0974 166.037 19.5941 166.037 23.5558C166.037 26.6444 168.298 29.5001 172.78 29.5001C175.637 29.5001 178.335 27.9154 179.287 26.5667V29.2607H183.332V16.3859C183.332 11.6318 180.514 8.58203 174.921 8.58203ZM179.125 20.5869C179.125 24.6683 176.307 26.3307 173.651 26.3307C171.352 26.3307 170.319 25.1017 170.319 23.4394C170.319 21.3405 172.223 20.3088 175.08 20.27L179.125 20.2312V20.5869Z"
          fill="currentColor"
        />
        <path
          d="M193.725 8.81805V6.91641C193.725 4.97597 195.033 4.34209 196.62 4.34209C197.452 4.34209 198.327 4.53937 198.842 4.69783V0.936601C198.087 0.700513 197.135 0.5 195.827 0.5C192.099 0.5 189.598 3.07433 189.598 7.03607V8.81805H186.305V12.4241H189.598V29.2607H193.725V12.4241H199V8.81805H193.725Z"
          fill="currentColor"
        />
        <path
          d="M11.2287 0.73291L0 29.2575H4.44677C7.96078 29.2057 11.0635 27.4496 12.9549 24.7783H6.22159L6.62319 23.7628L9.04899 17.5695H9.04575L10.989 12.657C11.9023 10.3608 12.6537 8.37827 13.5281 6.08208H13.567C14.3994 8.38151 15.154 10.3608 16.0673 12.657L17.4535 16.1627L18.0073 17.5695H18.0008L19.1797 20.5805C16.6211 21.1788 14.4156 22.6988 12.9452 24.7783H20.8315L22.6063 29.2575H27.0887L15.8309 0.73291H11.2287Z"
          fill="currentColor"
        />
      </svg>
    </Box>
  );
}
