import { Autocomplete, FormControl, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import MedicareNumber from '@/components/form-builder/components/input/MedicareNumber';
import NumberTextField from '@/components/form-builder/components/input/NumberTextField';
import type { InputElementProps, InputProps } from '@/components/form-builder/components/input/types';

const InputElement = (props: InputElementProps) => {
  const { type, onChange, name, options, value, inputProps, ...restInputProps } = props;
  const { trigger, clearErrors } = useFormContext();

  switch (type) {
    case 'list':
      const freeSolo = options ? { freeSolo: true, autoSelect: true } : {};
      return (
        <Autocomplete
          multiple
          options={options ?? []}
          {...freeSolo}
          value={typeof value === 'string' ? [] : value}
          onChange={(_e, newValue) => {
            onChange(newValue);
            clearErrors(name);
          }}
          renderInput={(params) => <TextField {...params} {...restInputProps} />}
        ></Autocomplete>
      );
    case 'medicareNumber':
      return <MedicareNumber {...props} />;
    case 'number':
      return <NumberTextField {...props} />;
    default:
      return (
        <TextField
          type="text"
          onChange={(args) => {
            onChange(args);
            clearErrors(name);
          }}
          onBlur={() => trigger(name)}
          value={value}
          inputProps={inputProps}
          {...restInputProps}
        />
      );
  }
};

const Input = ({ name, autofill, inputProps, ...rest }: InputProps) => {
  const { control, setValue, trigger } = useFormContext();

  return (
    <FormControl sx={{ my: 2 }} fullWidth>
      <Controller
        rules={{
          required: true
        }}
        control={control}
        defaultValue=""
        name={name}
        render={({ field: { onChange, value }, fieldState: { error, invalid, isDirty } }) => (
          <InputElement
            {...rest}
            error={!!error}
            helperText={error?.message}
            name={name}
            onChange={onChange}
            value={value}
            color={isDirty && !invalid ? 'success' : 'primary'}
            variant="filled"
            inputProps={{
              ...inputProps,
              'data-autofill': autofill
            }}
            onMouseDown={(e: React.MouseEvent<Element, MouseEvent>) => {
              const target = e.target as HTMLInputElement;
              if (e.altKey && target?.dataset?.autofill) {
                setValue(name, target?.dataset?.autofill);
                trigger(name);
              }
            }}
          />
        )}
      />
    </FormControl>
  );
};

export default Input;
