import { Box, Grid, styled, Typography } from '@mui/material';
import TransferListButtons from './components/TransferListButtons';
import TransferListSingle from './components/TransferListSingle';

const StyledBox = styled(Box)(({ theme }) => ({
  padding: 20,
  borderRadius: 5,
  backgroundColor: theme.palette.grey[200]
}));

const StyledHeading = styled(Typography)({
  textAlign: 'center',
  marginBottom: 10
});

export type TransferListItem = {
  id: number;
  label: string;
};

export type TransferListProps = {
  headingLeftSide: string;
  headingRightSide: string;
  checkedItems: TransferListItem[];
  leftSideItems: TransferListItem[];
  rightSideItems: TransferListItem[];
  handleMoveAllRightOnClick: () => void;
  handleMoveAllLeftOnClick: () => void;
  handleMoveCheckedLeftOnClick: () => void;
  handleMoveCheckedRightOnClick: () => void;
  handleItemOnClick: (item: TransferListItem) => void;
};

export default function TransferList(props: TransferListProps) {
  const {
    headingLeftSide,
    headingRightSide,
    checkedItems,
    leftSideItems,
    rightSideItems,
    handleMoveAllLeftOnClick,
    handleMoveAllRightOnClick,
    handleMoveCheckedLeftOnClick,
    handleMoveCheckedRightOnClick,
    handleItemOnClick
  } = props;

  return (
    <Grid container my={5}>
      <Grid item xs={5}>
        <StyledHeading variant="h6">{headingLeftSide}</StyledHeading>
        <StyledBox>
          <TransferListSingle
            listItems={leftSideItems}
            checkedItems={checkedItems}
            handleItemOnClick={handleItemOnClick}
            ariaLabel="left-side-list"
          />
        </StyledBox>
      </Grid>
      <Grid item xs={2} paddingTop={10}>
        <TransferListButtons
          checkedItems={checkedItems}
          leftSideItems={leftSideItems}
          rightSideItems={rightSideItems}
          handleMoveAllLeftOnClick={handleMoveAllLeftOnClick}
          handleMoveAllRightOnClick={handleMoveAllRightOnClick}
          handleMoveCheckedLeftOnClick={handleMoveCheckedLeftOnClick}
          handleMoveCheckedRightOnClick={handleMoveCheckedRightOnClick}
        />
      </Grid>
      <Grid item xs={5}>
        <StyledHeading variant="h6">{headingRightSide}</StyledHeading>
        <StyledBox>
          <TransferListSingle
            listItems={rightSideItems}
            checkedItems={checkedItems}
            handleItemOnClick={handleItemOnClick}
            ariaLabel="right-side-list"
          />
        </StyledBox>
      </Grid>
    </Grid>
  );
}
