/* eslint-disable no-control-regex */
// https://emailregex.com/
// General Email Regex (RFC 5322 Official Standard)
// Harry.S: Modified this to allow Capitals, and then we can lowercase so users don't get confused.
// I also split it apart for better feedback to the user
export const emailRegex =
  /^(?!.*\s)(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

// Regex to check for spaces anywhere in the email.
// This is to ensure the email does not contain whitespace, which is invalid.
const spaceRegex = /\s/;

const localPartRegex =
  /^(?!.*\s)(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")$/;

// Regex to detect consecutive dots, which are not allowed in the local part of the email.
const dotRegex = /\.{2,}/;

// Regex for quoted strings in the local part.
// This allows almost any character inside quotes, including special and whitespace characters.
const quotedStringRegex = /^"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*"$/;

// Regex for the domain part of the email (after the @ symbol).
// It allows for alphanumeric characters separated by dots. Each section (label) of the domain must start and end with an alphanumeric character.
// Hyphens are permitted within labels but not at the beginning or end.
// The top-level domain (TLD) must be at least two characters long and consist only of letters.
const domainPartRegex = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;

const ipLiteralRegex =
  /^\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]$/;

/**
 * ^
 * (?!.*\s)  // Negative lookahead to ensure there are no spaces anywhere in the email.
 * (?:  // Start of a non-capturing group for the local part of the email.
 *
 *   [a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+  // The first part of the local part, which allows certain characters.
 *   (?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*  // The subsequent part of the local part, allowing dots as long as they are not at the beginning or end or repeated.
 *
 *   |  // Alternation to allow quoted strings in the local part.
 *   "(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*"  // Allows almost any character within quotes, including special characters and spaces.
 *
 * )
 * @  // The @ symbol, which is mandatory in all email addresses.
 *
 * (?:  // Start of a non-capturing group for the domain part of the email.
 *   (?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+  // The domain part, which allows alphanumeric characters and hyphens.
 *   [a-zA-Z]{2,}  // The top-level domain must be at least two characters long and contain only letters.
 *
 *   |  // Alternation for IP address literal in square brackets.
 *   \[
 *     (?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.  // The first three octets of the IP address.
 *   ){3}
 *   (?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?  // The last octet of the IP address.
 *   |[a-z0-9-]*[a-zA-Z0-9]:  // Alternation for IPv6 addresses.
 *   (?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+  // The rest of the IPv6 address.
 *   )\]
 *
 * )$  // End of the regex, matching the end of the string.
 */

/**
 * Validates an email address string and returns an error string if invalid.
 * @param email - The email address to validate.
 * @returns - Returns null if the email is valid, or an error message string if invalid.
 */
export function validateEmail(email: string): string | null {
  if (!emailRegex.test(email)) {
    //If you modify this please ensure you understand why each is in a specific order( test whitespace and for consecutive dots first) and how the regex works

    // Ensure there's exactly one @ symbol in the email address.
    if (email.split('@').length !== 2) {
      return 'The email must contain exactly one @ symbol.';
    }

    // Split email into local and domain parts.
    const [localPart, domainPart] = email.split('@');

    const isQuotedLocal = localPart.startsWith('"') && localPart.endsWith('"');

    // Check for spaces in the email address.
    if (!isQuotedLocal && spaceRegex.test(localPart)) {
      return "The characters before the @ symbol shouldn't contain spaces.";
    }

    // Check for spaces in the email address.
    if (spaceRegex.test(domainPart)) {
      return "The characters after the @ symbol shouldn't contain spaces.";
    }

    // Check for consecutive dots in the local part.
    if (!isQuotedLocal && dotRegex.test(email)) {
      return "The email shouldn't contain consecutive dots.";
    }

    // If the local part is quoted, check for valid characters inside the quotes.
    if (isQuotedLocal && !quotedStringRegex.test(localPart)) {
      return 'The email can only contain certain special characters inside quotes.';
    }

    // Check the local part for allowed characters.
    if (!isQuotedLocal && !localPartRegex.test(localPart)) {
      return "The email should only contain letters, digits, and !#$%&'*+/=?^_`{|}~- characters before the @ symbol.";
    }

    // Check the domain part for allowed characters and structure
    if (!domainPartRegex.test(domainPart) && !ipLiteralRegex.test(domainPart)) {
      return 'The characters after the @ symbol are invalid. It usually can only contain letters, digits, and hyphens (not starting or ending with a hyphen).';
    }

    //catch all
    return 'The email address is invalid please try again';
  }
  // If all checks pass, the email is considered valid.
  return null;
}
