import type { ButtonProps as MuiButtonProps } from '@mui/material';
import { Box, FormControl, Button as MuiButton, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import type { NumberScaleType } from '../../FormType';
import ReactMarkdown from '../markdown/ReactMarkdown';

function numberArray(numItems: number): number[] {
  return Array.from({ length: numItems }, (_, i) => i + 1);
}

function labelNumber(numbers: number[], labels: string[], labelIndex: number): number {
  if (labelIndex < 0 || labelIndex >= labels.length) {
    throw new Error('Invalid label index');
  }

  const step = (numbers.length - 1) / (labels.length - 1);
  const numberIndex = Math.round(labelIndex * step);

  return numbers[numberIndex];
}

const buttonSize = 58;
const buttonBorder = 12;

const Button = styled((props: MuiButtonProps & { selected: boolean }) => <MuiButton {...props} />)(
  ({ theme: t, selected }) => ({
    width: buttonSize,
    height: buttonSize,
    fontSize: '1.2rem !important',
    marginRight: buttonBorder,
    marginBottom: buttonBorder,
    background: selected ? 'var(--mui-palette-primary-light)' : t.palette.grey[100],
    color: selected ? `${t.palette.primary.contrastText} !important` : undefined,

    '&:hover': {
      background: selected
        ? `${t.palette.primary.light} !important`
        : 'var(--mui-palette-primary-_states-focusVisible) !important'
    }
  })
);

type ScaleProps = {
  name: string;
  required?: boolean;
} & NumberScaleType;

export default function NumberScale({ name, buttons, labels, required = true }: ScaleProps) {
  const { control, trigger } = useFormContext();
  const rules = required ? { required: true } : {};

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const maxWidth = buttons * (buttonSize + buttonBorder) * 1.3;

  const numbers = numberArray(buttons);

  return (
    <Box display="flex" flexDirection="column" width="auto">
      <FormControl>
        <Controller
          rules={rules}
          control={control}
          defaultValue=""
          name={name}
          render={({ field: { onChange, value, ...rest } }) => {
            return (
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                maxWidth={maxWidth}
                sx={{
                  justifyContent: { xs: 'inherit', md: 'space-between' },
                  '& > button:last-child': {
                    marginRight: 0
                  }
                }}
                {...rest}
              >
                {numbers.map((num) => {
                  const scaleId = `${name}_${num ?? ''}`;
                  const selected = value === num;
                  return (
                    <Button
                      key={num}
                      id={scaleId}
                      variant="outlined"
                      selected={selected}
                      disableTouchRipple={selected}
                      onClick={() => {
                        onChange(num);
                        trigger(name);
                      }}
                    >
                      {num}
                    </Button>
                  );
                })}
              </Box>
            );
          }}
        />
      </FormControl>

      <Box
        display="flex"
        maxWidth={maxWidth}
        mt="0.5rem"
        mb="1rem"
        sx={{
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { xs: 'flex-start', md: 'space-between' }
        }}
      >
        {labels.map((label, index) => (
          <Typography key={index} color="text.secondary" sx={{ mb: isMobile ? '0.5rem' : undefined }}>
            {isMobile ? `${labelNumber(numbers, labels, index)} - ` : ''}
            <ReactMarkdown disallowedElements={['p']} unwrapDisallowed>
              {label}
            </ReactMarkdown>
          </Typography>
        ))}
      </Box>
    </Box>
  );
}
