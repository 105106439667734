type AnyObject = Record<string, unknown>;

/**
 * Flattens a nested object into a single-level object.
 * @param object - The parent level object
 * @returns - Returns a flattened object with parent key names appended.
 */
export default function flattenObject(object: unknown): AnyObject {
  const flattenedObj: AnyObject = {};

  function recurse(currentObj: unknown, parentKey?: string) {
    if (typeof currentObj !== 'object' || currentObj === null) {
      if (parentKey !== undefined) {
        flattenedObj[parentKey] = currentObj;
      }
      return;
    }

    if (Array.isArray(currentObj)) {
      currentObj.forEach((item, index) => {
        const newKey = parentKey ? `${parentKey}[${index}]` : `${index}`;
        recurse(item, newKey);
      });
    } else {
      for (const key in currentObj) {
        if (currentObj.hasOwnProperty(key)) {
          const newKey = parentKey ? `${parentKey}[${key}]` : key;
          recurse((currentObj as Record<string, unknown>)[key], newKey);
        }
      }
    }
  }

  recurse(object);
  return flattenedObj;
}
