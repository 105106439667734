import type { PCPPage, PCPUserData } from '@/pcp/constants/types';

export const initialTestingPage: PCPPage = 'pre_nurse';

export const initialTestingUserData: PCPUserData = {
  journeyCompleted: false,
  prefillFormData: {
    token: 'testing',
    email: 'testing@example.com'
  },
  preNurseConsultation: {
    completed: false,
    consultationBooked: true,
    calcomBookingUrl: 'https://bookings.alternaleaf.com.au/booking/omEvB...',
    preConsultationForm: {
      required: true,
      completed: false
    },
    qualityOfLifeForm: {
      required: true,
      completed: false
    },
    concessionCardDetailsForm: {
      required: false,
      completed: false
    }
  },
  preDoctorConsultation: {
    completed: false,
    consultationBooked: true,
    calcomBookingUrl: 'https://bookings.alternaleaf.com.au/booking/omEvB...',
    dassForm: {
      required: true,
      completed: false,
      formUrl: 'https://pms.alternaleaf.com.au/dass/1234/abcdefg-12...'
    }
  }
};
