import settings from '@/constants/constants';
import { Button, styled } from '@mui/material';

interface Props {
  gtmEventId?: string; //GTM event tracking do not change it.
  email: string;
}

const ConsultButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  textTransform: 'none',
  marginTop: '2rem',
  borderRadius: '4px',
  fontSize: '1rem',

  [theme.breakpoints.up('md')]: {
    width: '50%'
  }
}));

export default function PreConsultButton({ gtmEventId, email }: Props) {
  const preConsultUrl = `${settings.preConsultUrl}${document.location.search}`;

  const handleOnclick = () => {
    const url = new URL(preConsultUrl);
    try {
      url.searchParams.append('email', email || 'error-referencing-email');
      window.open(url, '_blank');
    } catch {
      window.open(url, '_self');
    }
  };

  return (
    <ConsultButton variant="contained" onClick={handleOnclick} id={gtmEventId}>
      Complete pre-consultation form
    </ConsultButton>
  );
}
