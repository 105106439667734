import {
  Box,
  Stepper as MuiStepper,
  Step,
  StepConnector,
  StepIcon,
  StepLabel,
  stepConnectorClasses as connectorClasses,
  stepIconClasses as iconClasses,
  stepLabelClasses as labelClasses,
  stepClasses,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';

type StagesProps = {
  stage: number;
};

const steps = ['Digital consultation', 'Nurse consultation', 'Doctor consultation'];

const Stepper = styled(MuiStepper)(({ theme: t }) => ({
  [t.breakpoints.down('md')]: {
    // below styles ensure stepper stretches to the edges of the parent container
    [`& .${stepClasses.root}`]: {
      ['&:first-of-type']: {
        paddingLeft: 0,
        [`& .${labelClasses.root}`]: {
          alignContent: 'flex-start',
          flexWrap: 'wrap'
        }
      },
      ['&:nth-of-type(2)']: {
        [`& .${connectorClasses.root}`]: {
          left: 'calc(-100% + 3.25rem)',
          right: 'calc(50% + 0.1rem)'
        }
      },
      ['&:last-of-type']: {
        paddingRight: 0,
        [`& .${labelClasses.root}`]: {
          alignContent: 'flex-end',
          flexWrap: 'wrap'
        },
        [`& .${connectorClasses.root}`]: {
          left: 'calc(-50% - 0.1rem)',
          right: '2.7rem'
        }
      }
    }
  }
}));

const Label = styled(StepLabel)(({ theme: t }) => ({
  // @deprecated - needs fixing in design system before can be removed
  // issue: style mismatch with PCP designs
  [`& .${labelClasses.label}`]: {
    fontSize: '0.75rem',
    letterSpacing: '0.0625rem',
    lineHeight: '1rem'
  },

  // ensure stepper stretches to the edges of the parent container
  [t.breakpoints.down('md')]: {
    position: 'relative',
    zIndex: 10,

    [`& .${labelClasses.labelContainer}`]: {
      width: '5.1rem'
    }
  }
}));

const Icon = styled(StepIcon)(({ theme: t }) => ({
  // @deprecated - needs fixing in design system before can be removed
  // issue: style mismatch with PCP designs
  [`&.${iconClasses.active}`]: {
    color: t.palette.secondary.main
  },

  // @deprecated - needs fixing in design system before can be removed
  // issue: style mismatch with PCP designs
  [`&.${iconClasses.completed}`]: {
    color: t.palette.success.main
  },

  // @deprecated - needs fixing in design system before can be removed
  // issue: style mismatch with PCP designs
  [`& .${iconClasses.text}`]: {
    fontSize: '0.9rem',
    fontWeight: 600
  }
}));

const Connector = styled(StepConnector)({
  // overriding how MUI positions connectors so that they sit in the middle
  [`&.${connectorClasses.alternativeLabel}`]: {
    top: 34,
    padding: '0 max(30%, 2rem)'
  },

  // @deprecated - needs fixing in design system before can be removed
  // issue: style mismatch with PCP designs
  [`& .${connectorClasses.line}`]: {
    borderTopWidth: '0.125rem'
  }
});

export default function Stages({ stage }: StagesProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box my="2.5rem">
      <Stepper activeStep={stage} alternativeLabel={isMobile} connector={<Connector />}>
        {steps.map((label) => (
          <Step key={label}>
            <Label StepIconComponent={Icon}>{label}</Label>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
