import { Alert, Box } from '@mui/material';

import type { FormTypes } from './FormType';
import { FormElements } from './FormType';
import { Checkbox, Date, Email, Input, NumberScale, Radio, Select, Signature } from './components';
import { LocationInput } from './components/input/LocationInput';
import ReactMarkdown from './components/markdown/ReactMarkdown';
import CustomModal from './components/modal/CustomModal';

type FormElementProps = {
  name: string;
} & FormTypes;

export default function FormElement(props: FormElementProps) {
  const {
    email,
    checkbox,
    radio,
    numberScale,
    number,
    input,
    list,
    date,
    text,
    select,
    signature,
    modal,
    locationInput,
    medicareNumber
  } = FormElements;
  switch (props.type) {
    case select:
      return <Select {...props} />;
    case checkbox:
      return <Checkbox {...props} />;
    case radio:
      return <Radio {...props} />;
    case numberScale:
      return <NumberScale {...props} />;
    case email:
      return <Email {...props} />;
    case medicareNumber:
    case number:
    case input:
    case list:
      return <Input {...props} />;
    case date:
      return <Date {...props} />;
    case signature:
      return <Signature {...props} />;
    case text:
      return (
        <Box className={props.className}>
          <ReactMarkdown>{props.value}</ReactMarkdown>
        </Box>
      );
    case modal:
      return <CustomModal type={props.type} title={props.title} content={props.content} />;
    case locationInput:
      return <LocationInput {...props} />;
    default:
      return <Alert severity="error">the form element type doesn&apos;t exist.</Alert>;
  }
}
