import { Box, Typography } from '@mui/material';

import Noise from '@/components/svg/icons/Noise';
import PrescriptionIcon from '@/components/svg/icons/Prescription';
import Question from '@/components/svg/icons/Question';
import Wifi from '@/components/svg/icons/Wifi';

type ItemProps = {
  IconStart?: React.ComponentType;
  children: React.ReactNode;
};

const Item = ({ IconStart, children }: ItemProps) => (
  <Box display="flex" flexDirection="row" alignItems="flex-start" mb="1rem">
    <Box sx={{ marginRight: '1rem', userSelect: 'none', color: 'primary.main' }}>{IconStart && <IconStart />}</Box>
    <Typography variant="body1" mt="0.2rem">
      {children}
    </Typography>
  </Box>
);

export default function WhatToBring() {
  return (
    <Box mb="2.5rem">
      <Typography variant="h5" component="h2" color="primary" mb="1.5rem">
        What to bring to your session
      </Typography>

      <Box display="flex" flexDirection="column">
        <Item IconStart={PrescriptionIcon}>
          A list of current prescription medications, including dosage and frequency
        </Item>
        <Item IconStart={Question}>Any questions and concerns you may have</Item>
        <Item IconStart={Wifi}>Stable internet connection and you are on a device with a camera and microphone</Item>
        <Item IconStart={Noise}>Find a quiet and private space for the consultation to ensure confidentiality</Item>
      </Box>
    </Box>
  );
}
