import { Typography } from '@mui/material';

import Stages from '@/pcp/components/components/Stages';
import Steps from '@/pcp/components/components/Steps';
import WhatToBring from '@/pcp/components/components/WhatToBring';
import type { PCPStepConfig } from '@/pcp/constants/types';
import DassForm from '@/pcp/content/steps/doctor/DassForm';
import DoctorConsult from '@/pcp/content/steps/doctor/DoctorConsult';

const doctorStepsConfig: PCPStepConfig[] = [
  {
    stepComponent: <DassForm />,
    stepId: 'dass_form',
    title: 'DASS form',
    buttonId: 'dass-form-step-button',
    requiredApiPath: 'preDoctorConsultation.dassForm.required',
    urlApiPath: 'preDoctorConsultation.dassForm.formUrl',
    completedApiPath: 'preDoctorConsultation.dassForm.completed',
    subtitleActive: '3 mins to complete',
    buttonTextActive: 'Complete now',
    buttonTextCompleted: 'Completed',
    ga4Events: {
      buttonClickEvents: [
        {
          event: 'dass_form_start',
          clickId: 'dass-form-step-button',
          dlv_click_id: 'dass-form-step-button',
          event_category: 'button-click'
        }
      ]
    }
  },
  {
    stepComponent: <DoctorConsult />,
    stepId: 'doctor_consult',
    title: 'Doctor consultation',
    buttonId: 'view-doctor-consult-step-button',
    urlApiPath: 'preDoctorConsultation.calcomBookingUrl',
    completedApiPath: 'preDoctorConsultation.completed',
    subtitleActive: 'View and reschedule',
    buttonTextActive: 'View consultation',
    buttonTextCompleted: 'Completed',
    ga4Events: {
      buttonClickEvents: [
        {
          event: 'click_view_doctor_consultation',
          clickId: 'view-doctor-consult-step-button',
          dlv_click_id: 'view-doctor-consult-step-button',
          event_category: 'button-click'
        }
      ]
    }
  }
];

export default function PreDoctor() {
  return (
    <>
      <Typography variant="h5" component="h1" color="primary">
        Your journey to better health
      </Typography>

      <Stages stage={2} />

      <Typography variant="body1" mb="1.5rem">
        You&apos;re all set for your doctor consultation!
      </Typography>

      <Steps stepsConfig={doctorStepsConfig} />

      <WhatToBring />

      <Typography variant="h5" component="h2" color="primary" mb="1.5rem">
        What to expect after your doctor consultation
      </Typography>
      <Typography variant="body1" mb="1rem">
        If you qualify after the evaluation, your doctor will create a personalised treatment plan.
      </Typography>
      <Typography variant="body1">
        You will be given access to a patient portal where you can view your prescriptions, purchase your products and
        manage your future bookings.
      </Typography>
    </>
  );
}
