import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import type { TextFieldVariants } from '@mui/material';
import { debounce, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

const StyledSearchTextField = styled(TextField)({
  marginBottom: 10,
  input: {
    minWidth: '250px',
    padding: '10px 15px'
  }
});

export type SearchTextFieldProps = {
  // callback on input change
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // callback when clicking clear button
  handleClear?: () => void;
  // text to display on search bar
  labelText: string;
  // enable clear functionality
  enableClear?: boolean;
  // time it takes for input to register in milliseconds
  debounceTime?: number;
  // filled, outlined or standard
  variant?: TextFieldVariants;
  // placeholder text
  placeholder?: string;
};

export default function SearchTextField({
  handleChange,
  handleClear,
  labelText,
  debounceTime = 1000,
  variant,
  enableClear = false,
  placeholder
}: SearchTextFieldProps) {
  const [value, setValue] = useState('');
  const [showClearIcon, setShowClearIcon] = useState(enableClear);

  useEffect(() => setShowClearIcon(enableClear && !!value), [enableClear, value]);

  const handleOnClear = () => {
    setValue('');

    if (handleClear) {
      handleClear();
    }
  };

  const handleChangeDebounced = useMemo(() => debounce(handleChange, debounceTime), [handleChange, debounceTime]);

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    handleChangeDebounced(event);
  };

  return (
    <StyledSearchTextField
      onChange={handleValueChange}
      label={labelText}
      variant={variant}
      value={value}
      placeholder={placeholder}
      InputProps={{
        endAdornment: showClearIcon && (
          <CloseIcon
            sx={{ cursor: 'pointer', position: 'absolute', right: 10 }}
            onClick={handleOnClear}
            aria-label="search-clear-button"
          />
        ),
        style: {
          paddingRight: 0
        }
      }}
      InputLabelProps={{
        style: { marginTop: '-5px' }
      }}
    />
  );
}
