import Cal, { getCalApi } from '@calcom/embed-react';
import { Box, Paper, styled } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import settings from '@/constants/constants';
import type { CalComBookingEvent } from '@/constants/types';
import { GoogleAnalyticsEventName, LaunchDarklyTestGroupType } from '@/constants/types';
import useGoogleTagManager from '@/hooks/useGoogleTagManager';
import useTrackingProviders from '@/hooks/useTrackingProviders';
import PrescreeningAndBookingCompleted from '@/pages/components/PrescreeningAndBookingCompleted';
import { mapBookingEventToQueryString } from '@/utils/calendar';
import { formatBookingDataToSha256HashedValues, formatStringToSha256Hash } from '@/utils/ga-hashing';

export type CalendarProps = {
  showCalendar: boolean;
  emailAddress: string | undefined;
  ldarkly_test_group: LaunchDarklyTestGroupType;
};

export const StyledFullPageOverlay = styled(Paper)(({ theme: t }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 10,
  backgroundImage: 'url(/assets/MobileMotif.svg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100%',
  height: '100%',
  width: '100%',
  padding: '5rem 0 0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  [t.breakpoints.up('md')]: {
    backgroundImage: 'url(/assets/DesktopMotif.svg)',
    backgroundSize: 'cover'
  }
}));

type PrescreeningAndBookingCompletedState = {
  show: boolean;
  email?: string;
  bookingStartDateTime?: Date;
};

export const handleBookingSuccessfulEvent = (
  calComBookingData: CalComBookingEvent,
  ldarkly_test_group: LaunchDarklyTestGroupType,
  promocode: string | null,
  sendGoogleAnalyticsEvent: (eventName: string, params: Record<string, string>) => void,
  setPrescreeningAndBookingDetails: (
    value:
      | ((prevState: PrescreeningAndBookingCompletedState) => PrescreeningAndBookingCompletedState)
      | PrescreeningAndBookingCompletedState
  ) => void,
  setShowBookingSuccessOverlay: (value: ((prevState: boolean) => boolean) | boolean) => void
) => {
  const sendMetrics = () => {
    sendGoogleAnalyticsEvent(GoogleAnalyticsEventName.SUBMIT_BOOKING, {
      event_category: 'cal.com',
      event_label: 'Initial Consultation',
      booking_id: calComBookingData?.booking?.uid || '',
      ...(promocode ? { promocode } : {}),
      /* SHA 256 Hashed values for GA tracking */
      ...formatBookingDataToSha256HashedValues(calComBookingData),
      ...(ldarkly_test_group !== LaunchDarklyTestGroupType.OFF && {
        ldarkly_feature_flag_id: 'payment_skipped_flow_experiment',
        ldarkly_test_group
      })
    });
  };

  const redirect = () => {
    if (ldarkly_test_group === LaunchDarklyTestGroupType.VARIANT) {
      setPrescreeningAndBookingDetails({
        show: true,
        email: calComBookingData.booking?.responses?.email || 'error-referencing-email',
        bookingStartDateTime: calComBookingData.booking?.startTime
          ? new Date(calComBookingData.booking.startTime)
          : undefined
      });
    } else {
      const existingQueryParams = window.location.search.replace('?', '');
      const bookingEventQueryParams = mapBookingEventToQueryString(calComBookingData);
      window.location.replace(`${settings.paymentsUrl}?${bookingEventQueryParams}&${existingQueryParams}`);
    }
  };

  setShowBookingSuccessOverlay(true);

  sendMetrics();
  setTimeout(() => {
    redirect();
  }, 1000); // delay of 1000ms to allow for the event to be sent to Google Analytics
};

export default function Calendar(props: CalendarProps) {
  const [prescreeningAndBookingDetails, setPrescreeningAndBookingDetails] =
    useState<PrescreeningAndBookingCompletedState>({
      show: false,
      email: undefined,
      bookingStartDateTime: undefined
    });

  const { showCalendar, emailAddress } = props;
  const [showBookingSuccessOverlay, setShowBookingSuccessOverlay] = useState(false);
  const [calendarConfig, setCalendarConfig] = useState({});
  const { ddTrackAction } = useTrackingProviders(settings);
  const { sendGoogleAnalyticsEvent } = useGoogleTagManager();
  const [searchParams] = useSearchParams();

  const calActionCounter = useRef(0);

  const calendarDisplayStyle = showCalendar ? 'block' : 'none';
  const promocode = searchParams.get('promocode');
  // const { mode } = useColorScheme();
  // const theme = mode && mode === 'dark' ? 'dark' : 'light';

  const phoneNumber = searchParams.get('phoneNumber') ?? '+61';
  const email = emailAddress || searchParams.get('email');
  const name = searchParams.get('name');

  useEffect(() => {
    (async function () {
      const CalComCalendar = await getCalApi();
      CalComCalendar('ui', {
        // theme,
        hideEventTypeDetails: false,
        layout: 'month_view'
      });

      CalComCalendar('on', {
        action: 'bookingSuccessful',
        callback: (e) => {
          const { data } = e.detail;

          handleBookingSuccessfulEvent(
            data as CalComBookingEvent,
            props.ldarkly_test_group,
            promocode,
            sendGoogleAnalyticsEvent,
            setPrescreeningAndBookingDetails,
            setShowBookingSuccessOverlay
          );
        }
      });

      CalComCalendar('on', {
        action: '__windowLoadComplete',
        callback: () => {
          if (ddTrackAction) {
            ddTrackAction('calendar window load completed');
          }
          sendGoogleAnalyticsEvent(GoogleAnalyticsEventName.START_BOOKING, {
            event_category: 'cal.com',
            event_label: 'Initial Consultation',
            ...(promocode ? { promocode } : {}),
            ...(email ? { sha_email: formatStringToSha256Hash(email) } : {})
          });
        }
      });

      CalComCalendar('on', {
        action: '__routeChanged',
        callback: () => {
          if (ddTrackAction) {
            const actionMessage =
              calActionCounter.current >= 1 ? 'calendar route changed' : 'calendar initial route changed';
            ddTrackAction(actionMessage);
            calActionCounter.current = calActionCounter.current + 1;
          }
        }
      });
    })();
  }, [sendGoogleAnalyticsEvent, ddTrackAction, promocode, props.ldarkly_test_group]);

  // Unfortunately, the Calcom 'identifier' doesn't allow snake_case format, else these
  // utm values could easily be passed automatically to their respective hidden input
  // fields on the Initial Consult booking form with no extra code. Instead, we have to
  // re-map these utm values to the unique Calcom camelCase 'identifiers' so they are passed
  // to the backend via the Calcom webhook. These values are used by the marketing team.
  const utmCampaign = searchParams.get('utm_campaign');
  const utmTerm = searchParams.get('utm_term');
  const utmMedium = searchParams.get('utm_medium');
  const utmSource = searchParams.get('utm_source');
  const utmContent = searchParams.get('utm_content');

  useEffect(() => {
    setCalendarConfig((prevState) => ({
      ...prevState,
      ...Object.fromEntries(searchParams),
      ...(email ? { email } : {}),
      ...(name ? { name } : {}),
      ...(utmCampaign ? { utmCampaign } : {}),
      ...(utmTerm ? { utmTerm } : {}),
      ...(utmMedium ? { utmMedium } : {}),
      ...(utmSource ? { utmSource } : {}),
      ...(utmContent ? { utmContent } : {}),
      ...(promocode ? { promocode } : {}),
      phoneNumber
    }));
  }, [searchParams, phoneNumber]);

  if (prescreeningAndBookingDetails.show) {
    return (
      <PrescreeningAndBookingCompleted
        email={prescreeningAndBookingDetails.email}
        bookingStartDateTime={prescreeningAndBookingDetails.bookingStartDateTime}
      />
    );
  }

  return (
    <>
      {showBookingSuccessOverlay && (
        <StyledFullPageOverlay>
          <CircularProgress color="primary" size="5rem" />
        </StyledFullPageOverlay>
      )}
      <Box sx={{ display: calendarDisplayStyle }} data-testid={`calendar-display-${calendarDisplayStyle}`}>
        <Cal
          calLink={settings.calendar.calLink}
          calOrigin={settings.calendar.calOrigin}
          config={calendarConfig}
          embedJsUrl={settings.calendar.embedJsUrl}
        ></Cal>
      </Box>
    </>
  );
}
