import CloseIcon from '@mui/icons-material/Close';
import type { BoxProps, DialogProps as MuiDialogProps } from '@mui/material';
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Dialog as MuiDialog,
  Slide,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type {} from '@mui/material/themeCssVarsAugmentation';
import type { TransitionProps } from '@mui/material/transitions';
import { forwardRef } from 'react';

type DialogProps = MuiDialogProps & {
  title?: string;
  closeButton?: boolean;
  onCloseButtonClick?: () => void;
  children?: React.ReactNode;
  containerProps?: BoxProps;
};

const borderRadius = 15;

const StyledDialog = styled(MuiDialog)(({ theme }) => ({
  // removing padding from embedded forms
  '.form-builder-step-container': {
    padding: '0'
  },

  '& .MuiDialog-paper': {
    borderRadius: `${borderRadius}px ${borderRadius}px 0 0`
  },

  // remove dialog content padding if has iframe
  ':has(iframe)': {
    '& .MuiPaper-root': {
      overflow: 'hidden'
    },
    '& .MuiDialogContent-root': {
      padding: 0,
      marginBottom: '-0.4rem'
    }
  },

  iframe: {
    width: '100%',
    height: '100%',
    border: 'none',
    borderRadius: `${borderRadius}px`
  },

  [theme.breakpoints.down('md')]: {
    top: '5%'
  },

  [theme.breakpoints.up('md')]: {
    '& .MuiDialog-paper': {
      borderRadius: `${borderRadius}px`
    },

    '& .MuiDialog-paper .MuiDialogContent-root > .MuiBox-root': {
      paddingBottom: '2rem'
    },

    iframe: {
      height: '50vh'
    }
  },

  [theme.breakpoints.up('lg')]: {
    '& .MuiDialog-container': {
      alignItems: 'flex-start'
    }
  },

  // leave a little margin to the top to see the title behind
  '@media (min-height: 680px)': {
    marginTop: '12%'
  }
}));

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Diaglog({
  title,
  closeButton = true,
  onCloseButtonClick,
  children,
  ...restOfDialogProps
}: DialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <StyledDialog
      fullWidth={true}
      maxWidth="md"
      TransitionComponent={Transition}
      closeAfterTransition
      {...restOfDialogProps}
      fullScreen={fullScreen}
    >
      <DialogContent>
        {title && <DialogTitle>{title}</DialogTitle>}
        {closeButton && (
          <IconButton
            aria-label="close"
            onClick={onCloseButtonClick}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              zIndex: 10,
              color: theme.vars.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        )}

        {children}
      </DialogContent>
    </StyledDialog>
  );
}
