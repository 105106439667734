interface FlattenedObject {
  [key: string]: string | boolean | number | Array<string | boolean | number>;
}

interface NestedObject {
  [key: string]: string | boolean | number | Array<string | boolean | number> | NestedObject;
}

/**
 * Restores a flattened object back to its original nested structure.
 * @param flattenedObj - The flattened object to restore.
 * @returns - Returns the original nested object structure.
 */
export function restoreObject(flattenedObj: FlattenedObject): NestedObject {
  const unflattenedObj: NestedObject = {};

  for (const key in flattenedObj) {
    if (flattenedObj.hasOwnProperty(key)) {
      const keys = key.split('[').map((k) => k.replace(']', ''));
      let currentObj = unflattenedObj;

      for (let i = 0; i < keys.length; i++) {
        const prop = keys[i];
        if (i === keys.length - 1) {
          if (/\[\d+\]$/.test(prop)) {
            const arrayProp = prop.substring(0, prop.indexOf('['));
            const match = prop.match(/\d+/);
            const index = match ? parseInt(match[0]) : 0;
            if (!currentObj[arrayProp]) {
              // If the property is not an array, initialize it as an empty array
              currentObj[arrayProp] = [];
            }
            // Assign the value to the array at the specified index
            (currentObj[arrayProp] as Array<unknown>)[index] = flattenedObj[key];
          } else {
            currentObj[prop] = flattenedObj[key];
          }
        } else {
          // If the property doesn't exist, initialize it as an empty object
          currentObj[prop] ||= {};
          // Update current object reference
          currentObj = currentObj[prop] as NestedObject;
        }
      }
    }
  }

  return unflattenedObj;
}
