import { hasValidMedicareChecksum } from '@/components/form-builder/components/input/checksum';
import NumberTextField from '@/components/form-builder/components/input/NumberTextField';
import type { InputElementProps } from '@/components/form-builder/components/input/types';
import { FF_MEDICARE_CHECKSUM } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';

const MedicareNumber = ({ helperText, ...props }: InputElementProps) => {
  const { flags } = useFeatureFlags();
  const isMedicareChecksumEnabled = flags[FF_MEDICARE_CHECKSUM];

  const badMedicareNumber =
    !props.error &&
    isMedicareChecksumEnabled &&
    typeof props.value === 'string' &&
    props.value.length === 10 &&
    !hasValidMedicareChecksum(props.value);

  return (
    <NumberTextField
      className="badMedicareNumber"
      helperText={
        badMedicareNumber
          ? "Oops! The number you entered doesn't look right. Try again or else you can verify your details in your appointment."
          : helperText
      }
      FormHelperTextProps={badMedicareNumber ? { sx: { color: 'warning.main' } } : undefined}
      sx={
        badMedicareNumber
          ? {
              '& .MuiInputLabel-root': {
                color: 'warning.main'
              },
              '& .MuiFilledInput-root': {
                '&::before, &::after, &:hover::before': {
                  borderBottomColor: 'warning.main'
                }
              }
            }
          : undefined
      }
      {...props}
    ></NumberTextField>
  );
};
export default MedicareNumber;
