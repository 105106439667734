import { Button } from '@montugroup/design-system';
import { Box, styled } from '@mui/material';

export const SectionWrapper = styled('section')(({ theme }) => ({
  display: 'flex',
  overflow: 'hidden',
  paddingBottom: '6rem',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  height: '100%',

  [theme.breakpoints.up('sm')]: {
    paddingBottom: 0,
    display: 'block'
  },

  ['p > a']: {
    color: 'rgb(255, 93, 42)'
  }
}));

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'fixed',
  bottom: 0,
  width: '100%',
  justifyContent: 'flex-end',
  padding: '1rem 1.5rem',
  zIndex: 1,
  left: 0,
  flexDirection: 'column',
  gap: '0.5rem',

  [theme.breakpoints.up('md')]: {
    position: 'inherit',
    padding: '1.5rem 0',
    gap: '1rem',
    justifyContent: 'initial',
    flexDirection: 'row',

    ['button:first-of-type']: {
      marginRight: 0
    }
  }
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: 700,

  [theme.breakpoints.up('md')]: {
    position: 'inherit',
    width: 'fit-content'
  }
}));
