import { FormControl, InputLabel, MenuItem, Select as MuiSelect } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import type { InputType } from '../../FormType';

type SelectProps = {
  name: string;
  required?: boolean;
} & InputType;

export default function Select({ name, options, label, required = true }: SelectProps) {
  const { control, trigger } = useFormContext();

  const rules = required ? { required: true } : {};

  return (
    <FormControl fullWidth variant="filled" sx={{ my: 2 }}>
      <Controller
        rules={rules}
        control={control}
        defaultValue=""
        name={name}
        render={({ field: { onChange, ...rest }, fieldState: { error, invalid, isDirty } }) => (
          <>
            <InputLabel>{label}</InputLabel>
            <MuiSelect
              {...rest}
              onChange={(args) => {
                onChange(args);
                trigger(name);
              }}
              error={!!error}
              color={isDirty && !invalid ? 'success' : 'primary'}
            >
              {options?.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </MuiSelect>
          </>
        )}
      />
    </FormControl>
  );
}
