import constants from '@/pcp/constants/constants';
import type {
  PCPPage,
  PCPStage,
  PCPStepConfig,
  PCPStepData,
  PCPSubmittedFormData,
  PCPUserData
} from '@/pcp/constants/types';
import { pcpFormStepIdStrings } from '@/pcp/constants/types';

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Returns the value of the key in `obj` at `path`.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getValueByPath(obj: Record<string, any>, path?: string): any {
  if (typeof path !== 'string') {
    return undefined;
  }
  return path.split('.').reduce((curObj, key) => (curObj && curObj[key] !== undefined ? curObj[key] : undefined), obj);
}

/**
 * Returns a new `obj` (non-destructive) with keys at paths set to corresponding
 * values as found in `pathValuePairs`. If a value is omitted, will toggle
 * between true/false if existing value at that path is a boolean.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setValuesByPath<T extends Record<string, any>>(obj: T, pathValuePairs: [string, any?][]): T {
  const result = structuredClone(obj);

  pathValuePairs.forEach(([path, value]) => {
    const keys = path.split('.');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let current = result as Record<string, any>;

    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        if (value === undefined && typeof current[key] === 'boolean') {
          current[key] = !current[key];
        } else {
          current[key] = value;
        }
      } else {
        if (typeof current[key] !== 'object' || current[key] == null) {
          current[key] = {};
        }
        current = current[key];
      }
    });
  });

  return result;
}

const submittedFormsLSKey = 'pcp-submitted-forms';

/**
 * Resets the value of `<submittedFormsLSKey>` in localStorage to an empty array.
 */
export function resetSubmittedForms() {
  window.localStorage.setItem(submittedFormsLSKey, '[]');
}

/**
 * Gets the value of `<submittedFormsLSKey>` in localStorage and returns the
 * parsed result. If `null`, will set the value to the default via a reset.
 */
export function getSubmittedForms(): PCPSubmittedFormData[] {
  let localValue = window.localStorage.getItem(submittedFormsLSKey);
  if (typeof localValue !== 'string') {
    resetSubmittedForms();
    localValue = window.localStorage.getItem(submittedFormsLSKey);
  }
  try {
    const parsedValue = JSON.parse(localValue as string);
    return parsedValue;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    resetSubmittedForms();
    return [];
  }
}

/**
 * Sets the value of `<submittedFormsLSKey>` in localStorage to a stringified
 * version of the provided `value`.
 */
export function setSubmittedForms(value: PCPSubmittedFormData[]) {
  try {
    window.localStorage.setItem(submittedFormsLSKey, JSON.stringify(value));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    resetSubmittedForms();
  }
}

/**
 * Adds a "submitted" form `id` to localStorage, along with a submission `timestamp`.
 * If a `timestamp` isn't provided, one reflecting the current time will be added.
 * If the `id` already exists, the timestamp will be updated.
 */
export function addSubmittedForm(id: string, timestamp?: number) {
  const formTimestamp = timestamp || Date.now();
  const formData: PCPSubmittedFormData = [id, formTimestamp];
  const submittedForms = getSubmittedForms();

  if (submittedForms.length === 0) {
    setSubmittedForms([formData]);
  }

  for (let i = 0; i < submittedForms.length; i++) {
    if (submittedForms[i][0] === id) {
      submittedForms[i][1] = formTimestamp;
      setSubmittedForms(submittedForms);
      return;
    }
  }
  submittedForms.push(formData);
  setSubmittedForms(submittedForms);
}

/**
 * Removes a "submitted" form `id` from localStorage.
 */
export function removeSubmittedForm(id: string) {
  const submittedForms = getSubmittedForms();

  for (let i = 0; i < submittedForms.length; i++) {
    if (submittedForms[i][0] === id) {
      submittedForms.splice(i, 1);
      setSubmittedForms(submittedForms);
      return;
    }
  }
}

/**
 * Checks if a "submitted" form `id` is in localStorage and, if a `timeout` is
 * provided, requires that it is still valid to return `true`.
 */
export function checkSubmittedForm(id: string, timeout?: number): boolean {
  const submittedForms = getSubmittedForms();

  if (submittedForms.length === 0) {
    return false;
  }
  if (!timeout) {
    return submittedForms.some((form) => form[0] === id);
  }

  for (let i = 0; i < submittedForms.length; i++) {
    if (submittedForms[i][0] === id) {
      const isExpired = Date.now() > submittedForms[i][1] + timeout;
      return !isExpired;
    }
  }

  return false;
}

/**
 * Determines the user's active `stage` based on the data in `userData`.
 */
export function getStage(userData: null): undefined;
export function getStage(userData: PCPUserData): PCPStage;
export function getStage(userData: PCPUserData | null): PCPStage | undefined;
export function getStage(userData: PCPUserData | null): PCPStage | undefined {
  if (!userData) {
    return undefined;
  }
  const paths = constants.completedApiPaths;

  const nurseComplete = getValueByPath(userData, paths.pre_nurse) === true;
  const doctorComplete = getValueByPath(userData, paths.pre_doctor) === true;
  const journeyComplete = getValueByPath(userData, paths.journey_complete) === true;

  if (journeyComplete) {
    return 'journey_complete';
  }
  if (doctorComplete) {
    return 'consults_complete';
  }
  if (nurseComplete) {
    return 'pre_doctor';
  }
  return 'pre_nurse';
}

/**
 * Parses `userData` and `error` to determine which "page" to render.
 */
export function getPage(userData: PCPUserData | null, error: unknown): PCPPage {
  const stage = getStage(userData);

  if (error) {
    if (error === 'no_token' || error === 'bad_token') {
      return error;
    }
    return '500';
  }

  if (!userData || !stage) {
    return 'blank';
  }
  return stage;
}

/**
 * Merges dynamic data from `userData` into static data provided in
 * `stepsConfig` to produce `stepsData`. Includes calculations for optimistic
 * submission/completion.
 */
export function getSteps(userData: PCPUserData | null, stepsConfig: PCPStepConfig[]): PCPStepData[] {
  if (!userData) {
    return [];
  }

  const stepsData = stepsConfig.flatMap((stepConfig) => {
    const {
      requiredApiPath,
      urlApiPath,
      completedApiPath,
      subtitleActive,
      subtitleCompleted,
      buttonTextActive,
      buttonTextCompleted,
      ...config
    } = stepConfig;

    const required = requiredApiPath ? getValueByPath(userData, requiredApiPath) : true;
    if (!required) {
      return [];
    }

    const url = getValueByPath(userData, urlApiPath) as string | undefined;
    const completed = getValueByPath(userData, completedApiPath) as boolean | undefined;
    const submitted = completed
      ? true
      : config?.optimisticSubmit
        ? checkSubmittedForm(config.stepId, config?.optimisticSubmitTimeout)
        : false;

    return [
      {
        ...config,
        ...(url ? { url } : {}),
        completed: !!completed,
        submitted: submitted,
        subtitle: completed ? subtitleCompleted : subtitleActive,
        buttonText: completed ? buttonTextCompleted : buttonTextActive
      }
    ];
  });

  return stepsData;
}

/**
 * Checks if all form steps from the provided array have been completed.
 */
export function areAllFormsComplete(steps: PCPStepData[]): boolean {
  const forms = steps.filter((stepData) => (pcpFormStepIdStrings as readonly string[]).includes(stepData.stepId));

  let allCompleted = true;
  for (let i = 0; i < forms.length; i++) {
    if (!forms[i].completed) {
      allCompleted = false;
      break;
    }
  }

  return allCompleted;
}

/**
 * Splits a CalCom booking url origin out from the path.
 */
export function splitCalBookingUrl(url?: string): [string, string] {
  if (!url) {
    return ['', ''];
  }

  const origin = url.match(/^(https?:\/\/)?[^/]+/)?.[0] || '';
  const path = origin ? url.replace(origin, '') : '';

  return [origin, path];
}
