import { Typography } from '@mui/material';
import type { Options } from 'react-markdown';
import ReactMD from 'react-markdown';

// ReactMarkdown wrapper to remap `p` tags to use MUI Typography component
export default function ReactMarkdown({ className, children, ...rest }: Options) {
  return (
    <ReactMD
      {...rest}
      components={{
        p: ({ children: html }) => (
          <Typography marginY={4} className={className}>
            {html}
          </Typography>
        ),
        a: ({ href, children: html }) => (
          <Typography
            component="a"
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: 'inherit'
            }}
          >
            {html}
          </Typography>
        )
      }}
    >
      {children}
    </ReactMD>
  );
}
