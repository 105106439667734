import { Typography } from '@mui/material';

import Stages from '@/pcp/components/components/Stages';
import Steps from '@/pcp/components/components/Steps';
import WhatToBring from '@/pcp/components/components/WhatToBring';
import type { PCPStepConfig } from '@/pcp/constants/types';
import NurseBook from '@/pcp/content/steps/nurse/NurseBook';
import NurseConsult from '@/pcp/content/steps/nurse/NurseConsult';
import NursePreConsultForm from '@/pcp/content/steps/nurse/NursePreConsultForm';
import QolForm from '@/pcp/content/steps/nurse/QolForm';

const nurseStepsConfig: PCPStepConfig[] = [
  {
    stepComponent: <NurseBook />,
    stepId: 'nurse_book',
    title: 'Book nurse consultation',
    urlApiPath: 'preNurseConsultation.calcomBookingUrl',
    completedApiPath: 'preNurseConsultation.consultationBooked',
    subtitleActive: '',
    buttonTextActive: 'Book now',
    buttonTextCompleted: 'Booked'
  },
  {
    stepComponent: <NursePreConsultForm />,
    stepId: 'nurse_pre_consult_form',
    title: 'Pre-consultation form',
    buttonId: 'pre-consult-form-step-button',
    optimisticSubmit: true,
    optimisticSubmitTimeout: 180000,
    requiredApiPath: 'preNurseConsultation.preConsultationForm.required',
    completedApiPath: 'preNurseConsultation.preConsultationForm.completed',
    subtitleActive: '3 mins to complete',
    buttonTextActive: 'Complete now',
    buttonTextCompleted: 'Completed',
    ga4Events: {
      buttonClickEvents: [
        {
          event: 'click_pre_consult_start',
          clickId: 'pre-consult-form-step-button',
          dlv_click_id: 'pre-consult-form-step-button',
          event_category: 'button-click'
        }
      ],
      formCompleteEvents: [
        {
          event: 'pre_consult_complete',
          clickId: 'pre-consult-complete',
          dlv_click_id: 'pre-consult-complete',
          event_category: 'form'
        }
      ]
    }
  },
  {
    stepComponent: <QolForm />,
    stepId: 'qol_form',
    title: 'Quality of life form',
    buttonId: 'qol-form-step-button',
    optimisticSubmit: true,
    optimisticSubmitTimeout: 180000,
    requiredApiPath: 'preNurseConsultation.qualityOfLifeForm.required',
    completedApiPath: 'preNurseConsultation.qualityOfLifeForm.completed',
    subtitleActive: '5-10 mins to complete',
    buttonTextActive: 'Complete now',
    buttonTextCompleted: 'Completed',
    ga4Events: {
      buttonClickEvents: [
        {
          event: 'click_qol_form_start',
          clickId: 'qol-form-step-button',
          dlv_click_id: 'qol-form-step-button',
          event_category: 'button-click'
        }
      ],
      formCompleteEvents: [
        {
          event: 'qol_form_complete',
          clickId: 'qol-form-complete',
          dlv_click_id: 'qol-form-complete',
          event_category: 'form'
        }
      ]
    }
  },
  {
    stepComponent: <NurseConsult />,
    stepId: 'nurse_consult',
    title: 'Nurse consultation',
    buttonId: 'view-nurse-consult-step-button',
    urlApiPath: 'preNurseConsultation.calcomBookingUrl',
    completedApiPath: 'preNurseConsultation.completed',
    subtitleActive: 'View and reschedule',
    buttonTextActive: 'View consultation',
    buttonTextCompleted: 'Completed',
    ga4Events: {
      buttonClickEvents: [
        {
          event: 'click_view_nurse_consultation',
          clickId: 'view-nurse-consult-step-button',
          dlv_click_id: 'view-nurse-consult-step-button',
          event_category: 'button-click'
        }
      ]
    }
  }
];

export default function PreNurse() {
  return (
    <>
      <Typography variant="h5" component="h1" color="primary">
        Your journey to better health
      </Typography>

      <Stages stage={1} />

      <Typography variant="body1" mb="1.5rem">
        Complete these before your nurse consult
      </Typography>

      <Steps stepsConfig={nurseStepsConfig} />

      <WhatToBring />

      <Typography variant="h5" component="h2" color="primary" mb="1.5rem">
        What to expect after your nurse consultation
      </Typography>
      <Typography variant="body1">
        Your nurse will talk with you about your treatment goals, answer any questions you have, and get everything
        ready for your doctor consultation. If you&apos;re eligible for our personalised alternative healthcare service,
        your nurse will schedule your doctor appointment to consider your circumstances.
      </Typography>
    </>
  );
}
