/**
 * Validates a parcel locker string.
 *
 * @param value - The parcel locker string to be validated.
 * @return Returns true if the parcel locker string is valid, false otherwise.
 *
 * The regex tests if the string contains:
 * - "Parcel Locker" (case insensitive)
 */
export const validateParcelLocker = (value: string): boolean =>
  !/(\d{5}\s?\d{5})?\s?(parcel\s*?locker)\s?(\d{5}\s?\d{5})?/i.test(value);

export const PARCEL_LOCKER_ERROR_MESSAGE =
  'Parcel lockers are not allowed. Please enter a residential or PO Box address.';
