import { FormControl, FormControlLabel, FormHelperText, Checkbox as MuiCheckbox } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import type { CheckboxType } from '../../FormType';
import ReactMarkdown from '../markdown/ReactMarkdown';

type CheckboxProps = {
  name: string;
} & CheckboxType;

export default function Checkbox({ name, label, checked, required }: CheckboxProps) {
  const { control, trigger } = useFormContext();

  const rules = required ? { required: true } : {};

  return (
    <FormControl sx={{ ml: 2, pr: 2 }} error={true}>
      <Controller
        rules={rules}
        control={control}
        name={name}
        defaultValue={checked}
        render={({ field: { onChange, value: val, ...rest }, fieldState: { error } }) => {
          return (
            <>
              <FormControlLabel
                sx={{ alignItems: 'flex-start' }}
                control={
                  <MuiCheckbox
                    {...rest}
                    checked={val}
                    onChange={(e) => {
                      onChange(e.target.checked);
                      trigger(name);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onChange(!val);
                        trigger(name);
                      }
                    }}
                    sx={{
                      marginTop: -2
                    }}
                  />
                }
                label={
                  <ReactMarkdown disallowedElements={['p']} unwrapDisallowed>
                    {label}
                  </ReactMarkdown>
                }
              />
              {error && <FormHelperText>This is required.</FormHelperText>}
            </>
          );
        }}
      />
    </FormControl>
  );
}
