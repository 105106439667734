import { PageWrapper } from './components/Branding';
import Message from './components/Message';

export default function SystemUnavailable() {
  return (
    <PageWrapper>
      <Message
        title="System temporarily unavailable"
        message="Bookings are temporarily disabled while we update our system. We appreciate your patience."
      />
    </PageWrapper>
  );
}
