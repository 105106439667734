export const pcpSteppedStageStrings = ['pre_nurse', 'pre_doctor'] as const;
export type PCPSteppedStage = (typeof pcpSteppedStageStrings)[number];

export const pcpDiscreteStageStrings = [...pcpSteppedStageStrings, 'journey_complete'] as const;
export type PCPDiscreteStage = (typeof pcpDiscreteStageStrings)[number];

export const pcpStageStrings = [...pcpDiscreteStageStrings, 'consults_complete'] as const;
export type PCPStage = (typeof pcpStageStrings)[number];

export const pcpConsultStepIdStrings = ['nurse_book', 'nurse_consult', 'doctor_consult'] as const;
export type PCPConsultStepId = (typeof pcpConsultStepIdStrings)[number];

export const pcpFormStepIdStrings = ['nurse_pre_consult_form', 'qol_form', 'dass_form'] as const;
export type PCPFormStepId = (typeof pcpFormStepIdStrings)[number];

export const pcpStepIdStrings = [...pcpConsultStepIdStrings, ...pcpFormStepIdStrings] as const;
export type PCPStepId = (typeof pcpStepIdStrings)[number];

export const pcpErrorTypeStrings = ['404', '500', 'no_token', 'bad_token'] as const;
export type PCPErrorType = (typeof pcpErrorTypeStrings)[number];

export type PCPPage = PCPStage | PCPErrorType | 'blank';

export type PCPSubmittedFormData = [string, number]; // `number` is unix timestamp representing submission date

export type PCPUrl = string | null;

export type PCPFormData<HasUrl extends boolean = false> = HasUrl extends true
  ? {
      required: boolean;
      completed: boolean;
      formUrl: PCPUrl;
    }
  : {
      required: boolean;
      completed: boolean;
    };

export type PCPStageData = {
  completed: boolean;
  consultationBooked: boolean;
  calcomBookingUrl: PCPUrl;
};

export type PCPGa4Event = {
  event: string;
  [param: string]: string;
};

export type PCPStepConfig = {
  stepComponent: React.ReactNode;
  stepId: PCPStepId;
  title: string;
  buttonId?: string;
  isLink?: boolean;
  openLinkNewTab?: boolean;
  optimisticSubmit?: boolean;
  optimisticSubmitTimeout?: number; // in milliseconds
  requiredApiPath?: string;
  urlApiPath?: string;
  completedApiPath: string;
  subtitleActive?: string;
  subtitleCompleted?: string;
  buttonTextActive: string;
  buttonTextCompleted?: string;
  ga4Events?: {
    buttonClickEvents?: PCPGa4Event[];
    formCompleteEvents?: PCPGa4Event[];
  };
};

export type PCPStepData = Omit<
  PCPStepConfig,
  | 'requiredApiPath'
  | 'urlApiPath'
  | 'completedApiPath'
  | 'buttonTextActive'
  | 'buttonTextCompleted'
  | 'subtitleActive'
  | 'subtitleCompleted'
> & {
  completed: boolean;
  submitted: boolean;
  url?: string;
  subtitle?: string;
  buttonText?: string;
};

export type PCPUserData = {
  journeyCompleted: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prefillFormData: Record<string, any>;
  preNurseConsultation: PCPStageData & {
    preConsultationForm: PCPFormData<false>;
    qualityOfLifeForm: PCPFormData<false>;
    concessionCardDetailsForm: PCPFormData<false>;
  };
  preDoctorConsultation: PCPStageData & {
    dassForm: PCPFormData<true>;
  };
};

export type PCPFetchConfig = {
  delay?: number;
  updateLoadingState?: boolean;
  manualUserData?: PCPUserData;
};

export type PCPFetchUserDataFunction = (config?: PCPFetchConfig) => void;

export type PCPUpdateUserDataFunction = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pathValuePairs: [string, any?][],
  config?: Omit<PCPFetchConfig, 'manualUserData'>
) => void;

export type PCPUpdateTestPageFunction = (page: PCPPage, config?: Omit<PCPFetchConfig, 'manualUserData'>) => void;

export type PCPDataContextType = {
  ffLoading: boolean;
  pcpEnabled: boolean;
  pcpTestingEnabled: boolean;
  testing: boolean | undefined;
  demoTesting: boolean | undefined;
  testPage: PCPPage;
  updateTestPage: PCPUpdateTestPageFunction;
  initialTestingUserData: PCPUserData;
  initialTestingPage: PCPPage;
  token: string | undefined;
  userData: PCPUserData | null;
  userDataLoading: boolean;
  setUserDataLoading: React.Dispatch<React.SetStateAction<boolean>>;
  error: null | unknown;
  setError: React.Dispatch<React.SetStateAction<null | unknown>>;
  fetchUserData: PCPFetchUserDataFunction;
  updateUserData: PCPUpdateUserDataFunction;
  stepsConfig: PCPStepConfig[];
  setStepsConfig: React.Dispatch<React.SetStateAction<PCPStepConfig[]>>;
};

export type PCPStepContextType = PCPStepData & {
  allSteps: PCPStepData[];
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
