import type { CircularProgressProps } from '@mui/material';
import { Box, CircularProgress } from '@mui/material';

export default function FullSpinner(props: CircularProgressProps) {
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      height="90vh"
      width="100vw"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress color="primary" {...props} />
    </Box>
  );
}
