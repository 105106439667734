import { Box, FormControl, FormHelperText, InputLabel, Typography } from '@mui/material';
import { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import SignatureCanvas from 'react-signature-canvas';

import type { InputType } from '../../FormType';

type SignatureProps = {
  name: string;
} & InputType;

export default function Signature({ name, label, placeholder, required = true }: SignatureProps) {
  const { control, trigger, getValues } = useFormContext();
  const sigCanvas = useRef<SignatureCanvas>(null);
  const rules = required ? { required: true } : {};

  const formatIntoPng = () => {
    if (sigCanvas?.current) {
      const dataURL = sigCanvas.current?.getTrimmedCanvas().toDataURL('image/png');
      return dataURL;
    }
    return null;
  };

  const signature = getValues(name) || formatIntoPng();

  return (
    <>
      <Typography variant="h6" component="p">
        {label}
      </Typography>
      {signature && <img height={20} src={signature} alt="signature thumbnail" />}
      <FormControl fullWidth variant="filled" sx={{ my: 2 }}>
        <Controller
          rules={rules}
          control={control}
          defaultValue=""
          name={name}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <>
              <Box sx={{ minHeight: '8rem', position: 'relative' }}>
                <InputLabel sx={{ fontSize: '0.75rem', top: '-9px', color: error ? 'warning.main' : 'grey' }}>
                  {placeholder}
                </InputLabel>
                <SignatureCanvas
                  ref={sigCanvas}
                  onEnd={() => {
                    onChange(formatIntoPng());
                    trigger(name);
                  }}
                  penColor="green"
                  canvasProps={{
                    style: {
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      backgroundColor: 'rgb(0,0,0,0.06)',
                      borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
                    }
                  }}
                />
              </Box>
              <Box
                component={FormHelperText}
                sx={{ marginLeft: 0, cursor: 'pointer', color: 'info.main' }}
                onClick={() => {
                  sigCanvas.current?.clear();
                }}
              >
                Clear Signature
              </Box>
            </>
          )}
        />
      </FormControl>
    </>
  );
}
