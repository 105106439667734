import type { ContainerProps } from '@mui/material';
import { Box, Container, Divider } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import AlternaleafLogo from '@/components/svg/Logo';

type HeaderProps = {
  containerMaxWidth?: ContainerProps['maxWidth'] | number;
};

const HeaderWrapper = styled(Box)(({ theme: t }) => ({
  width: '100%',
  height: '3.5rem',

  [t.breakpoints.up('md')]: {
    height: '5rem'
  }
}));

export default function Header({ containerMaxWidth = 'md' }: HeaderProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <HeaderWrapper>
      <Container
        maxWidth={typeof containerMaxWidth === 'string' ? containerMaxWidth : undefined}
        sx={{
          maxWidth: typeof containerMaxWidth === 'number' ? containerMaxWidth : undefined,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%',
          width: '100%'
        }}
      >
        <AlternaleafLogo width={160} />
      </Container>
      {isDesktop && <Divider />}
    </HeaderWrapper>
  );
}
