import type { FieldValues } from 'react-hook-form';

import FormBuilder from '@/components/form-builder/FormBuilder';
import constants from '@/constants/constants';
import metadata from '@/data/quality-of-life.json';

import { WithPageWrapper } from './components/Branding';

const settings = constants.qualityOfLifeForm;

type Props = {
  urlParams?: string | Record<string, string> | URLSearchParams;
  userToken?: string;
  callback?: (event: string, data: FieldValues) => void;
  onSubmit?: (data: FieldValues, error: unknown) => void;
  formType?: 'standalone' | 'portal';
  pageWrapper?: boolean;
};

export default function QualityOfLifeForm({
  urlParams,
  userToken,
  callback,
  onSubmit,
  formType = 'portal',
  pageWrapper = true
}: Props) {
  const queryParams = new URLSearchParams(urlParams || location.search);
  const defaultData = Object.fromEntries(queryParams);

  const adjustedMetadata =
    formType === 'portal'
      ? {
          ...metadata,
          steps: metadata.steps.filter((step) => !(step.qid === 'email'))
        }
      : metadata;

  return WithPageWrapper(
    <FormBuilder
      {...(adjustedMetadata as any)}
      defaultValues={defaultData}
      postUrl={`${settings.backendOrigin}/${settings.backendApiPath}`}
      userToken={userToken}
      callback={callback}
      onSubmit={onSubmit}
    />,
    pageWrapper
  );
}
