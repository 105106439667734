import axios from 'axios';
import { useEffect, useState } from 'react';

import settings from '@/constants/constants';

export enum DiscountCodeStatus {
  valid = 'valid',
  expired = 'expired',
  invalid = 'invalid'
}

type DiscountCode = {
  discountCode: string;
};

type DiscountCodeError = {
  status: DiscountCodeStatus.invalid;
  errorMessage: string;
} & DiscountCode;

type DiscountCodeExpired = {
  status: DiscountCodeStatus.expired;
  expiredAt: string;
} & DiscountCode;

type DiscountCodeAmount = {
  status: DiscountCodeStatus.valid;
  discountAmount: number;
  campaign: string;
} & DiscountCode;

type DiscountCodePercent = {
  status: DiscountCodeStatus.valid;
  discountPercent: number;
} & DiscountCode;

export type DiscountType = (DiscountCodeAmount & DiscountCodePercent) | DiscountCodeExpired | DiscountCodeError;

export default function useDiscountCode(couponCode: string) {
  const [data, setData] = useState<DiscountType | null>(null);
  const [error, setError] = useState<null | unknown>(null);
  const [isLoading, setLoading] = useState(false);

  const fetchData = async (discountCode: string) => {
    setLoading(true);
    try {
      const response = await axios.get(`${settings.url}/discount/validate`, { params: { discountCode } });
      setData(response.data);
      // eslint-disable-next-line @typescript-eslint/no-shadow
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (couponCode) {
      fetchData(couponCode);
    }
  }, [couponCode]);

  return { data, error, isLoading, refetch: fetchData };
}
