import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import type { EnvSettings } from '@/constants/constants';
import Logger from '@/utils/logger';

import { version } from '../../package.json';

const logger = new Logger('datadog.ts');

export default function init(settings: EnvSettings) {
  const { datadog } = settings;
  const configs = {
    version,
    applicationId: 'e1b73b0a-c18f-4de9-bd85-117c2cfefab2',
    clientToken: 'pubbf8840134742b36f1e1f6b55a2020a69',
    service: 'alternaleaf-forms',
    site: 'us3.datadoghq.com',
    ...datadog
  };

  if (datadog) {
    logger.debug('Initializing DataDog RUM');

    if (!datadogRum.getInternalContext()) {
      datadogRum.init({
        ...configs,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        enableExperimentalFeatures: ['feature_flags'],
        /*
         * If PII is discovered during a session replay, adding the data attribute data-dd-privacy="mask" will override specific elements
         * See: https://docs.datadoghq.com/real_user_monitoring/session_replay/privacy_options/#override-an-html-element
         */
        defaultPrivacyLevel: 'mask-user-input'
      });
    }
    datadogRum.startSessionReplayRecording();

    if (!datadogLogs.getInitConfiguration()) {
      datadogLogs.init({
        ...configs,
        forwardErrorsToLogs: true
      });
    }
  }
}

export function customAction(topic: string) {
  datadogRum.addAction(topic, {});
}
