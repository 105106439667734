import TagManager from 'react-gtm-module';

import Logger from '@/utils/logger';

import type { GTMType } from './TrackigTypes';

const logger = new Logger('googleTagManager.ts');

function configureGTM({
  googleTagManagerId,
  googleTagManagerAuth
}: {
  googleTagManagerId: string;
  googleTagManagerAuth: string;
}) {
  let initialised = false;
  const init = () => {
    if (googleTagManagerId) {
      logger.debug(`Initializing GoogleTagManager`);
      TagManager.initialize({
        gtmId: googleTagManagerId,
        auth: googleTagManagerAuth || ''
      });
      initialised = true;
    }
  };

  init();

  return (id: number, data: GTMType) => {
    if (initialised) {
      // logger.debug(`Tracking Event: ${event}`);
      logger.dir(data);

      const { stepName, ...rest } = data;

      TagManager.dataLayer({
        dataLayer: {
          step_number: id,
          step_name: stepName,
          ...rest
        }
      });
    } else {
      init();
    }
  };
}

export default configureGTM;
