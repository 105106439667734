import { CircularProgress } from '@mui/material';
import { useState } from 'react';

type IframeProps = React.IframeHTMLAttributes<HTMLIFrameElement> & {
  title: string;
  delay?: number;
};

export default function Iframe({ title, ...iframeProps }: IframeProps) {
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading && <CircularProgress sx={{ position: 'absolute', inset: 0, margin: 'auto' }} />}
      <iframe
        title={title}
        onLoad={() => setLoading(false)}
        style={{ visibility: loading ? 'hidden' : 'visible', userSelect: 'none' }}
        {...iframeProps}
      />
    </>
  );
}
