type ConfigObject = { [key: string]: string };

export function transformKeysToSnakeCase(data: ConfigObject): ConfigObject {
  const transformedObject: ConfigObject = {};

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const snakeCaseKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
      transformedObject[snakeCaseKey] = data[key];
    }
  }

  return transformedObject;
}
