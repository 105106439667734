import { Button } from '@montugroup/design-system';
import type { TypographyProps } from '@mui/material';
import { Box, Chip, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import type { ReactNode } from 'react';
import React from 'react';

import Logo from '../svg/Logo';
import MobileLogo from '../svg/MobileLogo';

import Breadcrumbs from './components/breadcrumbs/Breadcrumbs';

export const FormBuilderWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: '0 1.5rem',
  paddingTop: '2rem',
  gap: '1.5rem',

  // custom element to display a iframe like div i.e privary agreement
  ['.frame']: {
    maxHeight: '8rem',
    overflowY: 'scroll',
    padding: '0 1rem',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '10px',
    margin: '1.5rem 0',

    ['h4']: {
      margin: '0.5rem 0'
    },

    ['&&--medium']: {
      maxHeight: '10rem'
    }
  }
});

export const StyledModal = styled(Box)(({ theme }) => ({
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.background.paper,
  position: 'absolute',
  boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.2)',
  padding: theme.spacing(10),
  paddingBottom: theme.spacing(15),
  borderRadius: 15,
  maxHeight: '90vh',
  overflowY: 'auto',
  [theme.breakpoints.up('xs')]: {
    width: '90%'
  },
  [theme.breakpoints.up('sm')]: {
    width: '80%'
  },
  [theme.breakpoints.up('md')]: {
    width: '70%'
  },
  [theme.breakpoints.up('lg')]: {
    width: '50%'
  }
}));

export const StepContainer = ({ className, children }: { className?: string; children: ReactNode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      className={className}
      sx={{
        paddingTop: {
          lg: '6.25rem',
          md: '4.25rem'
        },
        display: 'flex',
        flexDirection: 'column',
        gap: isMobile ? '1.5rem' : '2rem',
        maxWidth: '46rem',
        minWidth: { md: '46rem' },
        margin: { md: '0 auto' }
      }}
    >
      {isMobile ? <MobileLogo /> : <Logo />}
      {children}
    </Box>
  );
};

export const StepWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flex: 1,
  height: '100%',
  top: 0,
  left: 0,
  transition: 'all 0.5s ease',

  ['.fade-enter']: {
    opacity: 0,

    [theme.breakpoints.up('md')]: {
      transform: 'translateY(-100%)'
    }
  },

  ['.fade-enter-active']: {
    opacity: 1,
    [theme.breakpoints.up('md')]: {
      transform: 'translateY(0%)'
    }
  },

  ['.fade-exit']: {
    opacity: 1,
    [theme.breakpoints.up('md')]: {
      transform: 'translateY(0%)'
    }
  },

  ['.fade-exit-active']: {
    opacity: 0,
    [theme.breakpoints.up('md')]: {
      transform: 'translateY(100%)'
    }
  },

  ['.fade-enter-active,.fade-exit-active']: {
    transition: 'opacity 400ms, transform 400ms'
  },

  ['.fade-out-enter']: {
    opacity: 0,
    [theme.breakpoints.up('md')]: {
      transform: 'translateY(100%)'
    }
  },

  ['.fade-out-enter-active']: {
    opacity: 1,
    [theme.breakpoints.up('md')]: {
      transform: 'translateY(0%)'
    }
  },

  ['.fade-out-exit']: {
    opacity: 1,
    [theme.breakpoints.up('md')]: {
      transform: 'translateY(0%)'
    }
  },

  ['.fade-out-exit-active']: {
    opacity: 0,
    [theme.breakpoints.up('md')]: {
      transform: 'translateY(-100%)'
    }
  },

  ['.fade-out-enter-active,.fade-out-exit-active']: {
    transition: 'opacity 400ms, transform 400ms'
  }
}));

export const StyledBreadCrumbs = styled(Breadcrumbs)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('sm')]: {
    display: 'block'
  }
}));

export const StepIndicator = styled(Chip)(({ theme }) => ({
  width: 'fit-content',
  alignSelf: 'end',

  [theme.breakpoints.up('sm')]: {
    alignSelf: 'start'
  }
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: '1rem',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  paddingLeft: 0,
  fontWeight: 'bold',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'transparent'
  }
}));

export const VisuallyHiddenText = styled(Typography)<TypographyProps & { as?: keyof React.JSX.IntrinsicElements }>({
  position: 'absolute',
  width: '1px',
  height: '1px',
  overflow: 'hidden'
});
