//! DO NOT EDIT THIS FILE IT IS GENERATED USING: pnpm generate-flags

// Description: Allows us to quickly disable and re-enable the booking buttons for consultations
// Default On Variation: true
// Default Off Variation: false
export const FF_DISABLE_CONSULTATION_BUTTONS = 'disable_consultation_buttons';

// Description: feature flag to display discount code input that will be pass through the pre-screening -> payments flow
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_DISCOUNT_CODE = 'enable_discount_code';

// Description: When active a users medicare number will be validated
// Default On Variation: true
// Default Off Variation: false
export const FF_MEDICARE_CHECKSUM = 'medicare_checksum';

// Description: Enable/disable Google Places API in the address input.
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_GOOGLE_PLACES = 'enable_google_places';

// Description: To toggle the visibility of the pre-prescription portal
// Default On Variation: true
// Default Off Variation: false
export const FF_PPP_ENABLED = 'ppp_enabled';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_EWAY_PAYMENTS = 'enable_eway_payments';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_EWAY_3DSECURE = 'enable_eway_3dsecure';

// Description: To allow testing with the various tokens for various scenarios
// Default On Variation: true
// Default Off Variation: false
export const FF_PPP_TESTING = 'ppp_testing';

// Description: A FF which allows us to do a percentage rollout (50/50 in this case) to test the payment providers Till vs Eway
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_TILL_EWAY_PAYMENTS_SPLIT = 'enable_till_eway_payments_split';

// Description: When enabled, the promo code input displays a promotion-specific helper message, replacing the default one.
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PROMOTION_HELPER_MESSAGE = 'enable_promotion_helper_message';

// Description: Enables Snowplow Tracking
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_SNOWPLOW = 'enable_snowplow';

// Description: When variant the payment page will be skipped in the prescreening flow, when control the payment info will be collected from patient, and when off the experiment is off and payment info will be collected from patient
// Default On Variation: variant
// Default Off Variation: off
export const FF_PAYMENT_SKIPPED_FLOW_EXPERIMENT = 'payment_skipped_flow_experiment';
