import { FormControl } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import dayjs from 'dayjs';
import { Controller, useFormContext } from 'react-hook-form';

import type { DateType } from '../../FormType';

const Date = ({ label, name, inputProps, legalAge }: DateType) => {
  const { control, setError, trigger, setValue } = useFormContext();

  const only18 = legalAge
    ? {
        minDate: dayjs().subtract(100, 'year'),
        maxDate: dayjs().subtract(18, 'year'),
        disableFuture: true
      }
    : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl fullWidth>
        <Controller
          rules={{
            required: true
          }}
          control={control}
          name={name}
          render={({ field: { onChange, value }, fieldState: { invalid, isDirty } }) => {
            return (
              <DateField
                color={isDirty && !invalid ? 'success' : 'primary'}
                onError={(error) => {
                  setError(name, { type: 'custom', message: error?.toString() });
                }}
                label={label}
                format="DD/MM/YYYY"
                value={value ? dayjs(value) : null}
                {...inputProps}
                {...only18}
                onChange={(val, context) => {
                  onChange(val);
                  if (!context.validationError) {
                    trigger(name);
                  }
                }}
                variant="filled"
                onMouseDown={(e: React.MouseEvent<Element, MouseEvent>) => {
                  if (e.altKey) {
                    setValue(name, inputProps?.disablePast ? dayjs().add(3, 'year') : dayjs().subtract(18, 'year'));
                    trigger(name);
                  }
                }}
              />
            );
          }}
        />
      </FormControl>
    </LocalizationProvider>
  );
};

export default Date;
