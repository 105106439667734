import { useLocation } from 'react-router-dom';

import type { PCPPage, PCPUserData } from '@/pcp/constants/types';
import { initialTestingPage, initialTestingUserData } from '@/pcp/content/testingData';
import useFeatureFlags from '@/pcp/hooks/useFeatureFlags';

export default function useTesting(): [boolean | undefined, PCPUserData, PCPPage] {
  const [ffLoading, { pcpEnabled, pcpTestingEnabled }] = useFeatureFlags();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const testQueryParam = queryParams.get('test');
  const testingRequested = typeof testQueryParam === 'string';

  let testing: boolean | undefined = false;
  if (ffLoading) {
    testing = undefined;
  }
  if (pcpEnabled && pcpTestingEnabled) {
    testing = testingRequested;
  }

  return [testing, initialTestingUserData, initialTestingPage];
}
