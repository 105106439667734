import { IconButton, Link, Modal, Typography } from '@mui/material';
import { useState } from 'react';

import type { ModalType } from '../../FormType';
import { StyledModal } from '../../styled';

export default function CustomModal(props: ModalType) {
  const { title, content } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Link component="button" onClick={handleOpen} sx={{ marginY: 3, fontWeight: 'bold' }}>
        {title}
      </Link>
      <Modal open={open} onClose={handleClose}>
        <StyledModal>
          <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 17, top: 11 }}>
            <Typography sx={{ fontSize: '1.3rem', color: 'text.primary', width: '2rem' }}>&#10006;</Typography>
          </IconButton>
          <Typography variant="h6" component="h2">
            {title}
          </Typography>
          <Typography sx={{ mt: 2 }}>{content}</Typography>
        </StyledModal>
      </Modal>
    </div>
  );
}
