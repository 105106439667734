import { CssBaseline, styled } from '@mui/material';

const Page = styled('div')(({ theme }) => ({
  minHeight: '100%',
  padding: '0.5rem',

  [theme.breakpoints.up('sm')]: {
    padding: '8rem 10% 3rem',
    height: 'inherit'
  },

  [theme.breakpoints.up('md')]: {
    backgroundPositionY: '0px'
  },

  [theme.breakpoints.up('lg')]: {
    padding: '3rem 25% 3rem'
  }
}));

const Logo = styled('img')(({ theme }) => ({
  content: `url(/images/SmallBrand@2x.png)`,
  margin: '5rem 0 2rem 1.5rem',
  width: '7.75rem',
  [theme.breakpoints.up('sm')]: {
    content: `url(/images/Alternaleaf_purple.svg)`,
    margin: '0 0 2rem 0',
    width: '13.625rem'
  }
}));

export const Container = styled('div')(({ theme }) => ({
  backgroundColor: '#FFF',
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: '6px',
  padding: '0.625rem',
  [theme.breakpoints.up('sm')]: {
    padding: '3rem'
  }
}));

type AlterleafProps = {
  children: React.ReactNode;
};

export default function Alternaleaf({ children }: AlterleafProps) {
  return (
    <Page>
      <CssBaseline />
      <Logo src="/images/Alternaleaf_purple.svg" />
      {children}
    </Page>
  );
}
