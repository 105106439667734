import { Alert, Backdrop, CircularProgress, LinearProgress, Link } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { GoogleAnalyticsEventName } from '@/constants/types';
import useGoogleTagManager from '@/hooks/useGoogleTagManager';
import type { GTMType, TrackingConfigType } from '@/providers/TrackigTypes';
import flattenObject from '@/utils/flattenObject';
import Logger from '@/utils/logger';
import mapObject from '@/utils/mapObject';

import { formatStringToSha256Hash } from '@/utils/ga-hashing';
import type { FormTypes, TextType } from './FormType';
import { Question, Section } from './sections';
import { FormBuilderWrapper, StepContainer, StepIndicator, StepWrapper, StyledBreadCrumbs } from './styled';

const logger = new Logger('FormBuilder.ts');

export type SectionStep = {
  id: number;
  pageTitle: string;
  title: string;
  type: 'section';
  content: TextType[];
  buttonHref?: string;
  buttonText?: string;
  isLastPage?: boolean | false;
  trackingConfig?: TrackingConfigType;
  trackingData: GTMType;
};

export type QuestionStep = {
  id: number;
  qid: string;
  pageTitle: string;
  title: string;
  legend?: string;
  type: 'question';
  subtitle?: string;
  content: FormTypes[];
  conditions: { [key: string]: number };
  fieldArray?: boolean;
  isLastPage?: boolean | false;
  trackingConfig?: TrackingConfigType;
  trackingData: GTMType;
};

export type Step = { shouldSave?: boolean } & (SectionStep | QuestionStep);

export type FormBuilderProps = {
  formId: string;
  postUrl: string;
  userToken?: string;
  stepIndicator?: boolean;
  progressBar?: boolean;
  breadcrumbs?: string[];
  steps: Step[];
  defaultValues?: FieldValues;
  activeStep?: number;
  callback?: (event: string, data: FieldValues) => void;
  handleShowCalendar: () => void;
  hasSubmittedForm?: boolean;
  onSubmit?: (data: FieldValues, error: unknown) => void;
};

// workaround for handling different data shape required when submitting QoL form
// we will need to refactor FormBuilder to allow for custom data shapes to keep it scaleable
async function submitQolForm(url: string, fieldData: unknown, userToken?: string) {
  const flattenedFieldData = flattenObject(fieldData);
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email');

  const flatData = {
    ...flattenedFieldData,
    ...(userToken ? { token: userToken } : {}),
    ...(email ? { email: email } : {})
  };

  const data = mapObject(flatData, {
    token: 'token',
    email: 'email',
    data: {
      daily_activities: {
        daily_jobs_ease: 'daily_jobs_ease',
        commuting_ease: 'commuting_ease',
        mobility_ease: 'mobility_ease',
        self_care_ease: 'self_care_ease'
      },
      relationships: {
        Initimate_relationships: 'Initimate_relationships',
        family_relationships: 'family_relationships',
        community_relationships: 'community_relationships'
      },
      emotional_condition: {
        despair: 'despair',
        worry: 'worry',
        sad: 'sad',
        calm_and_tranquil: 'calm_and_tranquil',
        agitation: 'agitation',
        energy: 'energy',
        control: 'control',
        coping: 'coping'
      },
      physical_condition: {
        pain_frequency: 'pain_frequency',
        pain_level: 'pain_level',
        pain_interferance: 'pain_interferance',
        sight_quality: 'sight_quality',
        hearing_quality: 'hearing_quality',
        communication_quality: 'communication_quality'
      }
    }
  });

  // string number -> number
  const sanitizeData = JSON.stringify(data).replaceAll(/"(\d)"/gi, '$1');

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: sanitizeData
  });
}

async function submitForm(url: string, data: unknown) {
  let formatData = data;

  if (typeof data === 'object') {
    const flattenedData = flattenObject(data);
    const urlQueries = new URLSearchParams(flattenedData as Record<string, string>).toString();
    formatData = { ...data, url: `${window.location.origin}/?${urlQueries}` };
  }

  // string boolean -> boolean
  const sanitizeData = JSON.stringify(formatData)
    .replaceAll(/"true"/gi, 'true')
    .replaceAll(/"false"/gi, 'false');

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: sanitizeData
  });
}

const FormBuilder = ({
  formId: LS_FORM_ID,
  postUrl,
  userToken,
  stepIndicator = false,
  progressBar = false,
  breadcrumbs,
  steps,
  defaultValues,
  activeStep,
  callback,
  handleShowCalendar,
  hasSubmittedForm = false,
  onSubmit
}: FormBuilderProps) => {
  const getInitialData = () => {
    if (defaultValues && Object.keys(defaultValues).length) {
      return defaultValues;
    }
    let data = sessionStorage.getItem(LS_FORM_ID);
    if (data) {
      try {
        data = JSON.parse(data);
      } catch (err) {
        logger.error(err);
      }
      return data;
    }
    return null;
  };

  const { sendGoogleAnalyticsEvent } = useGoogleTagManager();
  const [searchParams] = useSearchParams();
  const methods = useForm({ defaultValues: getInitialData() as FieldValues });

  const LS_PREV_STEP_ID = `${LS_FORM_ID}_prev_steps`;
  const prevStepArray = new Set<number>(JSON.parse(sessionStorage.getItem(LS_PREV_STEP_ID) || `[0]`));

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(
    activeStep ?? (Array.from(prevStepArray)[prevStepArray.size - 1] || 0)
  );

  const [classNameState, setClassNameState] = useState('fade');
  const [loading, setLoading] = useState(false);
  const [submissionError, setSubmissionError] = useState<boolean>(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const { getValues } = methods;

  const logEvents = useCallback(
    (event: string, data: FieldValues) => {
      if (callback) {
        callback(event, data);
      }
    },
    [callback]
  );

  useEffect(() => {
    const currentStep = steps[currentStepIndex];

    const values = { ...getValues(), variant: LS_FORM_ID };

    if (currentStep?.shouldSave) {
      if (hasSubmittedForm || (LS_FORM_ID === 'quality-of-life' && hasSubmitted)) {
        return;
      }
      setLoading(true);

      const lastPageTracking = sessionStorage.getItem('last_page_tracking');

      const { event = '', data = {} } = lastPageTracking ? JSON.parse(lastPageTracking) : {};

      // workaround for qol form until we can refactor for flexibility
      (LS_FORM_ID === 'quality-of-life' ? submitQolForm(postUrl, getValues(), userToken) : submitForm(postUrl, values))
        .then((response) => {
          setLoading(false);
          if (response.ok) {
            if (LS_FORM_ID === 'quality-of-life') {
              setHasSubmitted(true);
            }

            if (lastPageTracking) {
              // requirement from Incubeta to trigger final submit only if there's no submission error
              sendGoogleAnalyticsEvent(event, data);
              sessionStorage.removeItem('last_page_tracking');
            }

            logEvents(LS_FORM_ID, values);
            sessionStorage.removeItem(LS_FORM_ID);
            sessionStorage.removeItem(LS_PREV_STEP_ID);

            if (onSubmit) {
              onSubmit(values, false);
            }
          } else {
            const errorEventName = event.includes('consult')
              ? GoogleAnalyticsEventName.PRECONSULT_ERROR
              : GoogleAnalyticsEventName.PRESCREENING_ERROR;
            sendGoogleAnalyticsEvent(errorEventName, {
              ...data,
              error_code: response.status,
              error_message: 'Form submission error'
            });

            setCurrentStepIndex(currentStepIndex - 1);
            setSubmissionError(true);
            throw new Error(`Something went wrong`);
          }
        })
        .catch((error) => {
          setLoading(false);
          logger.error(error);
          if (onSubmit) {
            onSubmit(values, error ? error : true);
          }
        });
    } else {
      sessionStorage.setItem(LS_FORM_ID, JSON.stringify(values));
    }
  }, [
    LS_FORM_ID,
    LS_PREV_STEP_ID,
    callback,
    currentStepIndex,
    getValues,
    hasSubmittedForm,
    logEvents,
    postUrl,
    sendGoogleAnalyticsEvent,
    steps,
    hasSubmitted
  ]);

  const setNewCurrentStepIndex = (newIndex: number) => {
    if (newIndex > currentStepIndex) {
      setClassNameState('fade');
    } else {
      setClassNameState('fade-out');
    }

    setTimeout(() => {
      setCurrentStepIndex(newIndex);
      if (newIndex > currentStepIndex) {
        if (currentStepIndex == 0) {
          sessionStorage.removeItem(LS_PREV_STEP_ID);
        }

        const currentStep = steps[currentStepIndex];
        const { event, stepName, ...rest } = currentStep.trackingData;
        const payload = {
          event,
          data: {
            step_number: currentStep.id.toString(),
            step_name: stepName,
            ...rest
          }
        };

        if (!currentStep.isLastPage) {
          const addPromoCodeToTracking = [
            GoogleAnalyticsEventName.PRESCREENING_START as string,
            GoogleAnalyticsEventName.PRECONSULT_START as string
          ].includes(event);

          const promocode = searchParams.get('promocode');
          const userEmail = getValues().email;

          const trackingPayload = {
            ...payload.data,
            ...(promocode && addPromoCodeToTracking ? { promocode } : {}),
            ...(userEmail ? { sha_email: formatStringToSha256Hash(userEmail) } : {})
          };

          sendGoogleAnalyticsEvent(payload.event, trackingPayload);
        } else {
          sessionStorage.setItem('last_page_tracking', JSON.stringify(payload));
        }

        logEvents(`${LS_FORM_ID}_step_${currentStep.id}`, {
          form_id: LS_FORM_ID,
          step: newIndex,
          ...getValues()
        });

        prevStepArray.add(Number(currentStepIndex));
        sessionStorage.setItem(LS_PREV_STEP_ID, JSON.stringify(Array.from(prevStepArray)));
      }
    }, 50);
  };

  const generateFormStep = (step: Step) => {
    const isBookConsultationStep = (step as SectionStep).buttonText === 'Book consultation now';
    // Setup the 'Book consultation now' button to show the Calendar when clicked. Else just show next step
    const onNextStep = isBookConsultationStep ? handleShowCalendar : () => setNewCurrentStepIndex(currentStepIndex + 1);

    switch (step.type) {
      case 'section':
        return (
          <Section
            {...step}
            prevStepIndexId={LS_PREV_STEP_ID}
            currentStepIndex={currentStepIndex}
            setActiveStep={setNewCurrentStepIndex}
            onNextStep={onNextStep}
          />
        );
      case 'question':
        return (
          <Question
            {...step}
            prevStepIndexId={LS_PREV_STEP_ID}
            currentStepIndex={currentStepIndex}
            setActiveStep={setNewCurrentStepIndex}
          />
        );
      default:
        return null;
    }
  };

  const isQuestion = steps?.[currentStepIndex]?.type === 'question';

  const totalQuestionSteps = steps.filter((step) => step.type === 'question').length;
  const currentStepNumber = Number(currentStepIndex) + 1;

  const stepIndicatorLabel = `${currentStepNumber}/${totalQuestionSteps}`;
  const totalStepsPercentComplete = ((currentStepNumber - 1) / (steps.length - 1)) * 100;

  return (
    <FormBuilderWrapper>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {!loading && (
        <StepContainer className="form-builder-step-container">
          {submissionError && (
            <Alert variant="outlined" severity="error">
              There was an error while submitting the form. Please try again. If the problem persists, please contact us
              at <Link href="tel:1800 844 920">1800 844 920</Link>
            </Alert>
          )}
          {progressBar && <LinearProgress value={totalStepsPercentComplete} variant="determinate" color="secondary" />}
          {isQuestion && (
            <>
              {breadcrumbs && <StyledBreadCrumbs breadcrumbs={breadcrumbs} />}
              {stepIndicator && <StepIndicator label={stepIndicatorLabel} />}
            </>
          )}
          <StepWrapper>
            <SwitchTransition mode="out-in">
              <CSSTransition
                key={currentStepIndex}
                addEndListener={(node, done) => {
                  node.addEventListener('transitionend', done, false);
                }}
                classNames={classNameState}
              >
                <FormProvider {...methods}>{generateFormStep(steps[currentStepIndex])}</FormProvider>
              </CSSTransition>
            </SwitchTransition>
          </StepWrapper>
        </StepContainer>
      )}
    </FormBuilderWrapper>
  );
};

export default FormBuilder;
