import { useContext } from 'react';

import Dialog from '@/pcp/components/components/Dialog';
import Iframe from '@/pcp/components/components/Iframe';
import Step from '@/pcp/components/components/Step';
import { PCPStepContext } from '@/pcp/components/components/StepContextWrapper';
import useTracking from '@/pcp/hooks/useTracking';
import { PCPDataContext } from '@/pcp/pages/PCPortal';

export default function DassForm() {
  const { fetchUserData } = useContext(PCPDataContext);
  const { modalOpen, setModalOpen, url, isLink, ga4Events } = useContext(PCPStepContext);

  const { sendGa4Events } = useTracking();

  const handleButtonClick = (mouseEvent: React.MouseEvent<HTMLButtonElement>) => {
    sendGa4Events(ga4Events?.buttonClickEvents);

    if (!isLink) {
      if (url && mouseEvent.ctrlKey) {
        window.open(url, '_blank');
        return;
      }
      setModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    fetchUserData({ updateLoadingState: true, delay: 2000 });
  };

  return (
    <>
      <Step onButtonClick={handleButtonClick} divider />

      <Dialog open={modalOpen} onCloseButtonClick={handleModalClose}>
        <Iframe src={url} title="DASS Form" />
      </Dialog>
    </>
  );
}
