import type { DateView } from '@mui/x-date-pickers';

import type { LocationInputProps } from './components/input/LocationInput';

export enum FormElements {
  text = 'text',
  radio = 'radio',
  numberScale = 'numberScale',
  email = 'email',
  input = 'input',
  date = 'date',
  number = 'number',
  list = 'list',
  checkbox = 'checkbox',
  select = 'select',
  signature = 'signature',
  modal = 'modal',
  locationInput = 'locationInput',
  medicareNumber = 'medicareNumber'
}

export type RadioType = {
  name?: string;
  type: FormElements.radio;
  values: {
    id: number;
    label: string;
    value?: string | boolean;
  }[];
};

export type NumberScaleType = {
  name?: string;
  type: FormElements.numberScale;
  buttons: number;
  labels: string[];
};

export type ModalType = {
  name?: string;
  type: FormElements.modal;
  title: string;
  content: string;
};

export type TextType = {
  type: FormElements.text;
  value: string;
  className?: string;
};

export type CheckboxType = {
  name?: string;
  type: FormElements.checkbox;
  label: string;
  required?: boolean;
  checked?: boolean;
};

export type InputType = {
  name?: string;
  type:
    | FormElements.email
    | FormElements.number
    | FormElements.list
    | FormElements.input
    | FormElements.select
    | FormElements.signature
    | FormElements.medicareNumber;
  label: string;
  options?: string[];
  placeholder?: string;
  helperText?: string;
  required?: boolean;
  inputProps?: {
    size?: number;
    minlength?: number;
    maxlength?: number;
    min?: number;
    max?: number;
    format?: string;
  };
  autofill?: string;
};

export type DateType = {
  name: string;
  type: FormElements.date;
  label: string;
  placeholder: string;
  legalAge?: boolean;
  inputProps?: {
    format?: string;
    views?: DateView[];
    disableFuture?: boolean;
    disablePast?: boolean;
  };
};

export type LocationInputType = LocationInputProps & {
  type: FormElements.locationInput;
};

export type FormTypes =
  | RadioType
  | NumberScaleType
  | ModalType
  | DateType
  | InputType
  | TextType
  | CheckboxType
  | LocationInputType;
