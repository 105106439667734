export default function MobileLogo() {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.5" y="1.5" width="97" height="97" rx="48.5" fill="#CEEDFF" />
      <path d="M58.7158 35.2358H54.5508V63.7604H58.7158V35.2358Z" fill="#300431" />
      <path
        d="M70.3009 37.5742H66.1747V43.3179H62.6445V47.0436H66.1747V63.7606H70.3009V47.0436H75.7387V43.3179H70.3009V37.5742Z"
        fill="#300431"
      />
      <path
        d="M35.2287 35.2329L24 63.7575H28.4468C31.9608 63.7057 35.0635 61.9496 36.9549 59.2783H30.2216L30.6232 58.2628L33.049 52.0695H33.0458L34.989 47.157C35.9023 44.8608 36.6537 42.8783 37.5281 40.5821H37.567C38.3994 42.8815 39.154 44.8608 40.0673 47.157L41.4535 50.6627L42.0073 52.0695H42.0008L43.1797 55.0805C40.6211 55.6788 38.4156 57.1988 36.9452 59.2783H44.8315L46.6063 63.7575H51.0887L39.8309 35.2329H35.2287Z"
        fill="#300431"
      />
      <rect x="1.5" y="1.5" width="97" height="97" rx="48.5" stroke="white" strokeWidth="3" />
    </svg>
  );
}
