import { useEffect, useState } from 'react';

import Logger from '@/utils/logger';

const logger = new Logger('useLocalState.ts');

export default function useLocalState<T>(
  key: string,
  defaultValue: T
): [T, React.Dispatch<React.SetStateAction<T>>, boolean] {
  const [value, setValue] = useState(defaultValue);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const localValue = window.localStorage.getItem(key);
    if (localValue != null) {
      let parsedValue;
      try {
        parsedValue = JSON.parse(localValue);
      } catch (err) {
        logger.error(err);
        parsedValue = defaultValue;
      }
      setValue(parsedValue);
    }
    setLoading(false);
  }, [key]);

  useEffect(() => {
    if (!loading) {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value, loading]);

  return [value, setValue, loading];
}
