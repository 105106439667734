import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Chip, Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';

type BreadcrumbsProps = {
  breadcrumbs: string[];
  className?: string;
};

export default function Breadcrumbs({ breadcrumbs, className }: BreadcrumbsProps) {
  return (
    <MuiBreadcrumbs
      className={className}
      sx={{
        '& .MuiBreadcrumbs-separator': {
          fontSize: '1.5rem',
          lineHeight: '1rem'
        }
      }}
      separator="-"
      aria-label="breadcrumb"
    >
      {breadcrumbs?.map((text) => {
        const lastIndex = breadcrumbs.length;
        const isLastItem = breadcrumbs[lastIndex - 1] === text;
        return (
          <Typography key={text} color="text.primary" sx={{ display: 'flex', alignItems: 'center' }}>
            {isLastItem ? (
              <Chip
                component="span"
                size="small"
                sx={{ mr: 1.5, color: 'white', bgcolor: 'text.secondary' }}
                label={lastIndex}
              />
            ) : (
              <CheckCircleIcon
                color="success"
                sx={{ height: '1.75rem', width: '1.75rem', mr: 1.5 }}
                fontSize="inherit"
              />
            )}
            {text}
          </Typography>
        );
      })}
    </MuiBreadcrumbs>
  );
}
