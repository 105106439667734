/* eslint-disable no-console */
/**
 * Usage:-
 *     logs will show in development only, in production logs will be disabled
 *     to turn on in production set sessionStorage.debug = 'MG*';
 *
 *   import { Logger } from 'utils/logger';
 *   const logger = new Logger('MyComponent');
 *   ...
 *   ...
 *   logger.info(`hello world ${someValue}`);
 */
class Logger {
  private originalMethods: {
    log: typeof console.log;
    debug: typeof console.debug;
    info: typeof console.info;
    warn: typeof console.warn;
    error: typeof console.error;
    dir: typeof console.dir;
  };

  private readonly filePrefix: string;

  constructor(filePrefix: string) {
    this.originalMethods = {
      log: console.log,
      debug: console.debug,
      info: console.info,
      warn: console.warn,
      error: console.error,
      dir: console.dir
    };
    this.filePrefix = filePrefix;
  }

  private shouldLog = (): boolean => {
    const item = sessionStorage.getItem('debug');
    return item ? item.startsWith('MG*') : false;
  };

  private formatMessage = (type: string, ...args: any[]): any[] => {
    const colors: { [key: string]: string } = {
      log: 'grey',
      debug: 'blue',
      info: 'green',
      warn: 'orange',
      error: 'red',
      dir: 'purple'
    };

    const color = colors[type];
    return [`%c${this.filePrefix} ::`, `color: ${color}`, ...args];
  };

  /**
   * This method is used to log debug messages, and only shows up
   * if the sessionStorage.debug variable is set to 'MG*'
   * @param args needs description
   */
  public debug = (...args: any[]): void => {
    if (this.shouldLog()) {
      this.originalMethods.debug(...this.formatMessage('debug', ...args));
    }
  };

  public log = (...args: any[]): void => {
    this.originalMethods.log(...this.formatMessage('log', ...args));
  };

  public info = (...args: any[]): void => {
    this.originalMethods.info(...this.formatMessage('info', ...args));
  };

  public warn = (...args: any[]): void => {
    this.originalMethods.warn(...this.formatMessage('warn', ...args));
  };

  public error = (...args: any[]): void => {
    this.originalMethods.error(...this.formatMessage('error', ...args));
  };

  /**
   * This method is used to log dir messages, and only shows up
   * if the sessionStorage.debug variable is set to 'MG*'
   * @param args needs description
   */
  public dir = (...args: any[]): void => {
    if (this.shouldLog()) {
      this.originalMethods.dir(...this.formatMessage('dir', ...args));
    }
  };
}

export default Logger;
