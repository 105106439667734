import { FormControl, FormControlLabel, Radio as MuiRadio, RadioGroup } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import type { RadioType } from '../../FormType';
import ReactMarkdown from '../markdown/ReactMarkdown';

type RadioProps = {
  name: string;
  required?: boolean;
} & RadioType;

export default function Radio({ name, values, required = true }: RadioProps) {
  const { control, trigger } = useFormContext();

  const rules = required ? { required: true } : {};

  return (
    <FormControl sx={{ ml: 2, pr: 2 }}>
      <Controller
        rules={rules}
        control={control}
        defaultValue=""
        name={name}
        render={({ field: { onChange, ...rest } }) => {
          return (
            <RadioGroup
              {...rest}
              onChange={(e) => {
                onChange(e.target.value);
                trigger(name);
              }}
            >
              {values.map(({ id, label, value }) => {
                const radioId = `${name}_${value ?? ''}`;
                return (
                  <FormControlLabel
                    key={id}
                    value={value ?? label}
                    label={
                      <ReactMarkdown disallowedElements={['p']} unwrapDisallowed>
                        {label}
                      </ReactMarkdown>
                    }
                    sx={{ pb: '0.875rem' }}
                    control={<MuiRadio id={radioId} sx={{ py: 0, alignSelf: 'flex-start' }} />}
                  />
                );
              })}
            </RadioGroup>
          );
        }}
      />
    </FormControl>
  );
}
