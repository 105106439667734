import { FormControl, TextField } from '@mui/material';
import type { MouseEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { validateEmail } from '@/utils/email';

import type { InputType } from '../../FormType';

type EmailProps = {
  name: string;
} & InputType;

const Email = ({ name, label, placeholder, autofill }: EmailProps) => {
  const { control, trigger, clearErrors, setValue } = useFormContext();

  return (
    <FormControl fullWidth>
      <Controller
        rules={{
          required: 'An email is required',
          validate: (value) => {
            const invalidEmailError = validateEmail(value);
            return invalidEmailError ? invalidEmailError : true;
          }
        }}
        control={control}
        defaultValue=""
        name={name}
        render={({ field: { onChange, value }, fieldState: { error, isDirty, invalid } }) => (
          <TextField
            type="text"
            onChange={(args) => {
              onChange(args);
              clearErrors(name);
            }}
            onBlur={() => trigger(name)}
            value={value}
            label={label}
            placeholder={placeholder}
            variant="filled"
            error={!!error}
            color={isDirty && !invalid ? 'success' : 'primary'}
            helperText={error?.message}
            inputProps={{
              'data-autofill': autofill
            }}
            onMouseDown={(e: MouseEvent) => {
              const target = e.target as HTMLInputElement;
              if (e.altKey && target?.dataset?.autofill) {
                setValue(name, target?.dataset?.autofill);
                trigger(name);
              }
            }}
          />
        )}
      />
    </FormControl>
  );
};

export default Email;
