/**
 * This file contains utility functions for normalizing and hashing user data for GA tracking purposes,
 * following the plan outlined in the JIRA document "Event Match quality improvement plan":
 * https://montugroup.atlassian.net/wiki/spaces/DAM/pages/397738444/Event+Match+quality+improvement+plan
 */

import type { CalComBookingEvent } from '@/constants/types';
import CryptoJS from 'crypto-js';
import type { FieldValues } from 'react-hook-form';
import { getCalComNameInput } from './calcom';

export const normaliseString = (value: string): string => {
  return value.trim().toLowerCase();
};

/*
  Function to validate and format phone number for Google format.
  - Check if user phone number is in E.164 format
  - Check if phone number has correct digits
*/
export const normaliseAndHashPhoneNumberForGoogle = (phoneNumber: string): string | false => {
  // Return false if the phone number DOES NOT start with a '+' symbol
  if (!phoneNumber.startsWith('+')) {
    return false;
  }

  // Return false if phone number DOES NOT follow E.164 format (11 to 15 digits after the '+')
  const e164Regex = /^\+\d{11,15}$/;
  if (!e164Regex.test(phoneNumber)) {
    return false;
  }

  // Return hashed phone number string
  return CryptoJS.SHA256(phoneNumber.trim()).toString(CryptoJS.enc.Hex);
};

/*
  Function to validate and format phone number for Meta format.
  - Remove any leading + symbol
  - Remove leading zeros
  - Ensure the number starts with 61 (Australia phone code)
*/
export const normaliseAndHashPhoneNumberForMeta = (phoneNumber: string): string | false => {
  // Remove any '+' symbols
  const formattedNumber = phoneNumber.trim().replace(/\+/g, '');

  // Check if the number starts with '61'
  if (!formattedNumber.startsWith('61')) {
    return false;
  }

  // Return hashed phone number string
  return CryptoJS.SHA256(formattedNumber).toString(CryptoJS.enc.Hex);
};

/* Function to SHA-256 hash values which come from the Calcom booking data */
export const formatBookingDataToSha256HashedValues = (calComBookingData: CalComBookingEvent) => {
  const responseData = calComBookingData.booking?.responses;
  const attendeeData = calComBookingData.booking?.attendees;
  const userEmail = responseData?.email;
  const phoneNumberGoogle =
    responseData?.phoneNumber && normaliseAndHashPhoneNumberForGoogle(responseData?.phoneNumber);
  const phoneNumberMeta = responseData?.phoneNumber && normaliseAndHashPhoneNumberForMeta(responseData?.phoneNumber);

  const userName = getCalComNameInput(responseData?.name);
  const userCity = attendeeData?.[0]?.timeZone?.split('/')[1];
  const userCountry = attendeeData?.[0]?.timeZone?.split('/')[0] === 'Australia' ? 'au' : false;

  return {
    ...(userEmail ? { sha_email: formatStringToSha256Hash(userEmail) } : {}),
    ...(phoneNumberGoogle ? { sha_gads_phone_number: phoneNumberGoogle } : {}),
    ...(phoneNumberMeta ? { sha_fb_phone_number: phoneNumberMeta } : {}),
    ...(userName?.firstName ? { sha_first_name: formatStringToSha256Hash(userName.firstName) } : {}),
    ...(userName?.lastName ? { sha_last_name: formatStringToSha256Hash(userName.lastName) } : {}),
    ...(userCity ? { sha_city: formatStringToSha256Hash(userCity) } : {}),
    ...(userCountry ? { sha_country: formatStringToSha256Hash(userCountry) } : {})
  };
};

/* Function to SHA-256 hash values which come from the Pre-consult form data */
export const formatPreConsultDataToSha256HashedValues = (formValues: FieldValues) => {
  const userEmail = formValues.email;
  const userFirstName = formValues.consent_form.first_name;
  const userLastName = formValues.consent_form.last_name;
  const userPostcode = formValues.address.postcode;
  const userCity = formValues.address.suburb;
  const userState = formValues.address.state;

  return {
    ...(userEmail ? { sha_email: formatStringToSha256Hash(userEmail) } : {}),
    ...(userFirstName ? { sha_first_name: formatStringToSha256Hash(userFirstName) } : {}),
    ...(userLastName ? { sha_last_name: formatStringToSha256Hash(userLastName) } : {}),
    ...(userPostcode ? { sha_postcode: formatStringToSha256Hash(userPostcode) } : {}),
    ...(userCity ? { sha_city: formatStringToSha256Hash(userCity) } : {}),
    ...(userState
      ? {
          sha_gads_region: formatStringToSha256Hash(userState),
          sha_fb_region: formatStringToSha256Hash(userState)
        }
      : {})
  };
};

/* Function to hash values for GA tracking */
export const formatStringToSha256Hash = (value: string) => {
  const sanitisedString = normaliseString(value);
  return CryptoJS.SHA256(sanitisedString).toString(CryptoJS.enc.Hex);
};
