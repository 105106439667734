import { datadogRum } from '@datadog/browser-rum';
import type { LDEvaluationDetail } from 'launchdarkly-react-client-sdk';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import type { ComponentType } from 'react';

import settings from '@/constants/constants';

const { clientSideID } = settings.launchDarklyConfig;

export default function withFeatureFlagProvider(App: ComponentType) {
  return withLDProvider({
    clientSideID,
    options: {
      inspectors: [
        {
          type: 'flag-used',
          name: 'dd-inspector',
          method: (key: string, detail: LDEvaluationDetail) => {
            datadogRum.addFeatureFlagEvaluation(key, detail.value);
          }
        }
      ]
    },
    reactOptions: {
      useCamelCaseFlagKeys: false
    }
  })(App);
}
