import { useLDClient } from 'launchdarkly-react-client-sdk';
import type { ReactNode } from 'react';
import { createContext, useEffect, useMemo, useState } from 'react';

import Logger from '@/utils/logger';

export const FFLoadingStateContext = createContext({
  loading: true
});

export function FFLoadingStateProvider({ children }: { children: ReactNode }) {
  const [loading, setLoading] = useState(true);
  const ldClient = useLDClient();
  const logger = new Logger('FFLoadingStateProvider');

  const userContext = {
    kind: 'user',
    anonymous: true,
    key: 'shared-anonymous'
  };

  useEffect(() => {
    if (ldClient) {
      ldClient.waitUntilReady().then(() => {
        ldClient.identify(userContext).then(() => {
          logger.info('LD client identify success', { ...userContext });
        });
        setLoading(false);
      });
    }
  }, [ldClient]);

  const value = useMemo(() => ({ loading }), [loading]);

  return (
    <div key="ff-loading-state-context">
      <FFLoadingStateContext.Provider value={value}>{children}</FFLoadingStateContext.Provider>
    </div>
  );
}
