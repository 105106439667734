import type { AxiosError } from 'axios';
import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import constants from '@/pcp/constants/constants';
import type {
  PCPDataContextType,
  PCPFetchUserDataFunction,
  PCPPage,
  PCPStepConfig,
  PCPUpdateTestPageFunction,
  PCPUpdateUserDataFunction,
  PCPUserData
} from '@/pcp/constants/types';
import { pcpStageStrings } from '@/pcp/constants/types';
import useFeatureFlags from '@/pcp/hooks/useFeatureFlags';
import useLocalState from '@/pcp/hooks/useLocalState';
import useTesting from '@/pcp/hooks/useTesting';
import { delay, setValuesByPath } from '@/pcp/utils/helpers';

export default function useUserData(): PCPDataContextType {
  const { token } = useParams();
  const [ffLoading, { pcpEnabled, pcpTestingEnabled }] = useFeatureFlags();
  const [testing, initialTestingUserData, initialTestingPage] = useTesting();
  const demoTesting = testing === undefined ? undefined : testing && !token;
  const [testPage, setTestPage, testPageLoading] = useLocalState<PCPPage>('pcp-test-page', initialTestingPage);

  const [userData, setUserData] = useState<PCPUserData | null>(null);
  const [userDataLoading, setUserDataLoading] = useState(true);
  const [error, setError] = useState<null | unknown>(null);
  const [stepsConfig, setStepsConfig] = useState<PCPStepConfig[]>([]);

  /**
   * Entrypoint function to update `userData` and therefore control PCP state.
   */
  const fetchUserData: PCPFetchUserDataFunction = async ({
    delay: delayMs,
    updateLoadingState = true,
    manualUserData
  } = {}) => {
    const bypassNormalFetch = manualUserData || (testing && !token);

    if (bypassNormalFetch) {
      setError(null);
      if (typeof delayMs === 'number') {
        if (updateLoadingState) {
          setUserDataLoading(true);
        }
        await delay(delayMs);
      }
      if (testing && userData == null) {
        setUserData(initialTestingUserData);
      }
      if (manualUserData) {
        setUserData(manualUserData);
      }
      setUserDataLoading(false);
      return;
    }
    // normal fetch...
    if (typeof token !== 'string') {
      setUserDataLoading(false);
      setError('no_token');
      return;
    }

    if (updateLoadingState) {
      setUserDataLoading(true);
    }
    setError(null);

    try {
      if (typeof delayMs === 'number') {
        await delay(delayMs);
      }

      const response = await axios.post(
        `${constants.backendOrigin}/${constants.backendApiPath}`,
        { tokenId: token },
        { headers: { 'Content-Type': 'application/json' } }
      );
      const data: PCPUserData = response.data;

      setUserData(data);
    } catch (err) {
      if ((err as AxiosError)?.response?.status === 400) {
        setError('bad_token');
      } else {
        setError(err);
      }
    } finally {
      setUserDataLoading(false);
    }
  };

  /**
   * AVOID USING OUTSIDE OF `testing` MODE.
   * All production logic for updating userData should go through `fetchUserData`.
   *
   * Convenience wrapper using `setValuesByPath()` to update existing userData
   * based on `pathValuePairs` without performing a "real" fetch.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateUserData: PCPUpdateUserDataFunction = (pathValuePairs, config) => {
    const data = userData || initialTestingUserData;
    const updatedData = setValuesByPath(data, pathValuePairs);
    fetchUserData({ manualUserData: updatedData, ...config });
  };

  /**
   * Updates `testPage` state and maintains semantically-correct userData
   * structure when pages associated with particular data shapes are selected
   * (stages).
   */
  const updateTestPage: PCPUpdateTestPageFunction = (page, config) => {
    if ((pcpStageStrings as readonly string[]).includes(page)) {
      const paths = constants.completedApiPaths;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let pathValuePairs: [string, any?][] = [];
      if (page === 'pre_nurse') {
        pathValuePairs = [
          [paths.pre_nurse, false],
          [paths.pre_doctor, false],
          [paths.journey_complete, false]
        ];
      }
      if (page === 'pre_doctor') {
        pathValuePairs = [
          [paths.pre_nurse, true],
          [paths.pre_doctor, false],
          [paths.journey_complete, false]
        ];
      }
      if (page === 'consults_complete') {
        pathValuePairs = [
          [paths.pre_nurse, true],
          [paths.pre_doctor, true],
          [paths.journey_complete, false]
        ];
      }
      if (page === 'journey_complete') {
        pathValuePairs = [
          [paths.pre_nurse, true],
          [paths.pre_doctor, true],
          [paths.journey_complete, true]
        ];
      }
      updateUserData(pathValuePairs, config);
    }
    setTestPage(page);
  };

  // initial fetch
  useEffect(() => {
    if (!pcpEnabled || testing === undefined) {
      return;
    }
    fetchUserData();
  }, [pcpEnabled, testing, token]);

  // ensure initial userData is correct shape when testing
  useEffect(() => {
    if (!testing || testPageLoading) {
      return;
    }
    updateTestPage(testPage);
  }, [testing, testPageLoading]);

  return useMemo(
    () => ({
      ffLoading,
      pcpEnabled,
      pcpTestingEnabled,
      testing,
      demoTesting,
      testPage,
      updateTestPage,
      initialTestingUserData,
      initialTestingPage,
      token,
      userData,
      userDataLoading,
      setUserDataLoading,
      error,
      setError,
      fetchUserData,
      updateUserData,
      stepsConfig,
      setStepsConfig
    }),
    [
      ffLoading,
      pcpEnabled,
      pcpTestingEnabled,
      testing,
      demoTesting,
      testPage,
      updateTestPage,
      initialTestingUserData,
      initialTestingPage,
      token,
      userData,
      userDataLoading,
      setUserDataLoading,
      error,
      setError,
      fetchUserData,
      updateUserData,
      stepsConfig,
      setStepsConfig
    ]
  );
}
