import { createContext, useMemo, useState } from 'react';

import type { PCPStepContextType, PCPStepData } from '@/pcp/constants/types';

type StepContextWrapperProps = {
  allSteps: PCPStepData[];
  stepData: PCPStepData;
  children: React.ReactNode;
};

export const PCPStepContext = createContext<PCPStepContextType>({} as PCPStepContextType);

/**
 * A wrapper for `Step` that provides context.
 */
export default function StepContextWrapper({ allSteps, stepData, children }: StepContextWrapperProps) {
  const [modalOpen, setModalOpen] = useState(false);

  const stepContextValue: PCPStepContextType = useMemo(
    () => ({ allSteps, modalOpen, setModalOpen, ...stepData }),
    [allSteps, modalOpen, setModalOpen, stepData]
  );

  return <PCPStepContext.Provider value={stepContextValue}>{children}</PCPStepContext.Provider>;
}
