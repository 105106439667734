import type { ButtonProps } from '@mui/material/Button';
import MUIButton from '@mui/material/Button';
import { forwardRef } from 'react';

/**
 * currently a wrapper for the MUI button but will restrict/extend
 * the amount of props that can be passed on to the button in the future
 *
 * @returns JSXElement
 *
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { children, className, ...rest }: ButtonProps,
  ref
) {
  return (
    <MUIButton className={className} variant="contained" {...rest} ref={ref}>
      {children}
    </MUIButton>
  );
});

export default Button;
