import {
  Clear as ClearIcon,
  Close as CloseIcon,
  Refresh as RefreshIcon,
  Settings as SettingsIcon
} from '@mui/icons-material';
import type { TooltipProps as MuiTooltipProps, SelectChangeEvent } from '@mui/material';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  ListSubheader as MuiListSubheader,
  Tooltip as MuiTooltip,
  Select,
  Slide,
  Switch,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { ArrowLeftIcon, ArrowRightIcon } from '@mui/x-date-pickers';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TestStepsSelect from '@/pcp/components/components/TestStepsSelect';
import constants from '@/pcp/constants/constants';
import type { PCPPage, PCPStage } from '@/pcp/constants/types';
import { pcpStageStrings, pcpSteppedStageStrings } from '@/pcp/constants/types';
import useLocalState from '@/pcp/hooks/useLocalState';
import { PCPDataContext } from '@/pcp/pages/PCPortal';

const testTokens = [
  ['nurse: pre-consult comp', '7228174f-71d0-4071-b9f5-52348ae6f889'],
  ['nurse: all forms comp', '2a3dee44-87ee-4254-b7fe-9d03af878a1f'],
  ['doctor: no booking, no DASS', 'a97b8956-a061-4d96-8d2c-0cfbdada9aaf'],
  ['doctor: no booking, DASS', '5ed215ab-40db-4767-8ed1-6897c5bce416'],
  ['doctor: booked, no DASS', '1189047c-82ad-4a35-99fc-b7e1ddead16d'],
  ['doctor: booked, DASS', '22abbd7a-7ced-49d5-b8e6-8112ce9f281b'],
  ['doctor: booked, DASS comp', '4210be28-36d0-447b-af9d-55455fe22e6f'],
  ['doctor consult complete', 'bf9cdf8e-6cb5-43ca-b2d1-f66d3b89e5c7'],
  ['journey complete', '682834ba-02d9-4ddd-b418-c9e1d8f4a0d6']
];

const stagePageItems: [PCPPage, string][] = [
  ['pre_nurse', 'nurse stage'],
  ['pre_doctor', 'doctor stage'],
  ['consults_complete', 'consults complete'],
  ['journey_complete', 'journey complete']
];
const errorPageItems: [PCPPage, string][] = [
  ['no_token', 'no token'],
  ['bad_token', 'bad token'],
  ['404', '404'],
  ['500', '500']
];

const Wrapper = styled(Box)(({ theme: t }) => ({
  position: 'fixed',
  zIndex: 10,
  top: 0,
  right: 0,
  padding: '0.1rem 1rem 0 1rem',
  height: '5.4rem',
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  maxWidth: '100vw',
  borderRadius: '0 0 0 10px',
  overflowY: 'hidden',
  scrollbarWidth: 'thin',

  [t.breakpoints.down('lg')]: {
    height: '5rem',
    paddingTop: 0,
    paddingRight: '0.4rem'
  }
}));

const ListSubheader = styled(MuiListSubheader)(({ theme: t }) => ({
  color: t.palette.text.disabled,
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontSize: '0.8rem',
  lineHeight: '2rem'
}));

const Tooltip = ({
  title,
  show = true,
  delay = 500,
  children,
  ...muiTooltipProps
}: MuiTooltipProps & { title: string; show?: boolean; delay?: number; children: React.ReactNode }) => (
  <MuiTooltip
    title={show ? title : ''}
    enterDelay={delay}
    enterNextDelay={delay}
    disableInteractive
    disableTouchListener
    arrow
    {...muiTooltipProps}
  >
    {children}
  </MuiTooltip>
);

export default function TestingMenu() {
  const [menuOpen, setMenuOpen, menuOpenLoading] = useLocalState('pcp-testing-menu-open', true);
  const [hideMenuIcon, setHideMenuIcon] = useLocalState('pcp-testing-menu-hide-icon', false);
  const [simulateLoading, setSimulateLoading] = useLocalState('pcp-testing-simulate-loading', false);
  const [prevTokens, setPrevTokens, prevTokensLoading] = useLocalState<string[]>('pcp-testing-prev-token', []);
  const [forceLoading, setForceLoading] = useState(false);

  const {
    token,
    userData,
    demoTesting,
    testPage,
    updateTestPage,
    initialTestingUserData,
    setUserDataLoading,
    fetchUserData
  } = useContext(PCPDataContext);
  const isTestToken = testTokens.some((testTokenData) => testTokenData[1] === token);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const isTestStagePage = demoTesting && (pcpStageStrings as readonly string[]).includes(testPage);
  const isTestSteppedStagePage = demoTesting && (pcpSteppedStageStrings as readonly string[]).includes(testPage);
  const numStagePages = stagePageItems.length;
  const testStageIndex: number | undefined = (() => {
    if (!isTestStagePage) {
      return undefined;
    }
    const stage = testPage as PCPStage;
    for (let i = 0; i < stagePageItems.length; i++) {
      if (stagePageItems[i][0] === stage) {
        return i;
      }
    }
    return undefined;
  })();

  const handleMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!menuOpen && event.shiftKey) {
      setHideMenuIcon(!hideMenuIcon);
      return;
    }
    setMenuOpen(!menuOpen);
  };

  const handleUseTokenChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if ((event?.nativeEvent as MouseEvent)?.shiftKey) {
      // eslint-disable-next-line no-console
      console.log('userData:', userData);
      return;
    }

    if (checked) {
      navigate(`/${constants.frontendPath}/${testTokens[1][1]}?test`);
    } else {
      navigate(`/${constants.frontendPath}?test`);
      fetchUserData({ manualUserData: initialTestingUserData });
    }
  };

  const handleSimLoadingChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const shiftHeld = (event?.nativeEvent as MouseEvent)?.shiftKey;
    if (shiftHeld || forceLoading) {
      if (checked) {
        return setForceLoading(true);
      }
      if (!checked && forceLoading) {
        setForceLoading(false);
      }
    }
    setSimulateLoading(checked);
  };

  const handleTokenFetch = () => {
    fetchUserData();
  };

  const handleTokenChange = (event: SelectChangeEvent) => {
    const targetToken = event.target.value;
    navigate(`/${constants.frontendPath}/${targetToken}?test`);
  };

  const handlePageChange = (event: SelectChangeEvent) => {
    const targetPage = event.target.value as PCPPage;
    updateTestPage(targetPage, { delay: simulateLoading ? 1000 : 0 });
  };

  const handlePrevPageClick = () => {
    if (typeof testStageIndex !== 'number') {
      return;
    }
    updateTestPage(stagePageItems?.[testStageIndex - 1]?.[0], { delay: simulateLoading ? 1000 : 0 });
  };

  const handleNextPageClick = () => {
    if (typeof testStageIndex !== 'number') {
      return;
    }
    updateTestPage(stagePageItems?.[testStageIndex + 1]?.[0], { delay: simulateLoading ? 1000 : 0 });
  };

  const handleRecentClearClick = () => {
    setPrevTokens((prev) => {
      let onlyCurrent = prev.filter((item) => item === token);
      if (onlyCurrent.length > 1) {
        onlyCurrent = onlyCurrent.slice(0, 1);
      }
      return onlyCurrent;
    });
  };

  useEffect(() => {
    setUserDataLoading(forceLoading);
  }, [forceLoading]);

  useEffect(() => {
    setForceLoading(false);
  }, [token]);

  useEffect(() => {
    if (!prevTokensLoading && token && !isTestToken && token !== prevTokens[prevTokens.length - 1]) {
      setPrevTokens((prev) => {
        let tokens = [...prev];
        tokens = tokens.slice(-3);
        if (tokens.find((item) => item === token)) {
          return tokens;
        }
        if (tokens.length >= 3) {
          tokens.shift();
        }
        tokens.push(token);
        return tokens;
      });
    }
  }, [token, prevTokensLoading]);

  if (menuOpenLoading) {
    return null;
  }

  return (
    <Wrapper
      boxShadow={menuOpen ? theme.shadows[3] : undefined}
      bgcolor={menuOpen ? 'rgba(255, 255, 255, 0.88)' : 'none'}
      sx={{
        backdropFilter: menuOpen ? 'blur(20px)' : undefined,
        transition: 'box-shadow 0.1s ease-in-out',
        overflowX: menuOpen ? 'auto' : 'hidden'
      }}
    >
      <Tooltip
        title={`SHIFT-CLICK: ${hideMenuIcon ? 'Show' : 'Hide'} menu icon`}
        show={!menuOpen}
        delay={hideMenuIcon ? 0 : undefined}
      >
        <IconButton
          onClick={handleMenuButtonClick}
          disableTouchRipple
          disableFocusRipple
          sx={{
            color: menuOpen ? 'primary.main' : hideMenuIcon ? 'rgba(255,255,255,0)' : 'grey.600',
            marginLeft: isMobile ? '-1.2rem' : 0
          }}
        >
          {menuOpen ? <CloseIcon /> : <SettingsIcon />}
        </IconButton>
      </Tooltip>

      <Slide in={menuOpen} direction="down">
        <Box display="flex" flexDirection="row" alignItems="center">
          {/* Token-based page control */}
          {token && (
            <>
              <Button
                onClick={handleTokenFetch}
                variant="contained"
                sx={{ height: '2.6rem', borderRadius: '4px', width: isMobile ? '4rem' : '8rem' }}
              >
                <RefreshIcon sx={{ fontSize: '1.1rem', mr: '0.25rem', position: 'relative', bottom: 1 }} />
                {isMobile ? '' : 'RE-FETCH'}
              </Button>

              <FormControl sx={{ ml: '0.8rem' }}>
                <InputLabel id="token-select-label">{isTestToken ? 'Test Token' : 'Token'}</InputLabel>
                <Select
                  labelId="token-select-label"
                  id="token-select"
                  value={token}
                  label={isTestToken ? 'Test Token' : 'Token'}
                  onChange={handleTokenChange}
                  sx={{ width: isMobile ? '10.5rem' : '20rem', height: '2.8rem' }}
                  MenuProps={{
                    disableScrollLock: true
                  }}
                >
                  <ListSubheader>Test Tokens</ListSubheader>
                  {testTokens.map((testTokenData) => (
                    <MenuItem key={testTokenData[1]} value={testTokenData[1]}>
                      {testTokenData[0]}
                      <Box display="inline" marginLeft="0.8rem" color="text.disabled">
                        ({testTokenData[1].substring(0, 6)}...)
                      </Box>
                    </MenuItem>
                  ))}
                  {prevTokens.length > 0 && <Divider />}
                  {prevTokens.length > 0 && (
                    <ListSubheader>
                      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        Recent Tokens
                        {!(prevTokens.length === 1 && prevTokens[0] === token) && (
                          <IconButton
                            onClick={handleRecentClearClick}
                            disableTouchRipple
                            disableFocusRipple
                            sx={{
                              position: 'relative',
                              left: 5,
                              padding: '0.18rem',
                              color: 'grey.400'
                            }}
                          >
                            <ClearIcon sx={{ margin: 0, fontSize: '1.1rem' }} />
                          </IconButton>
                        )}
                      </Box>
                    </ListSubheader>
                  )}
                  {prevTokens.length > 0 &&
                    prevTokens.map((prevToken, index) => (
                      <MenuItem key={index} value={prevToken}>
                        {prevToken}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </>
          )}

          {/* State-based page control */}
          {demoTesting && (
            <>
              {isTestSteppedStagePage && <TestStepsSelect disabled={forceLoading} />}

              <FormControl sx={{ ml: '0.8rem' }}>
                <InputLabel id="page-select-label">Page</InputLabel>
                <Select
                  labelId="page-select-label"
                  id="page-select"
                  value={testPage}
                  disabled={forceLoading}
                  label="Page"
                  onChange={handlePageChange}
                  sx={{ width: isMobile ? '8rem' : '14rem', height: '2.8rem' }}
                  MenuProps={{
                    disableScrollLock: true
                  }}
                >
                  <ListSubheader>Stages</ListSubheader>
                  {stagePageItems.map((pageData) => (
                    <MenuItem key={pageData[0]} value={pageData[0]}>
                      {pageData[1]}
                    </MenuItem>
                  ))}
                  {isTestStagePage && (
                    <Box display="flex" flexDirection="row" justifyContent="space-between" my={3}>
                      <Button
                        onClick={handlePrevPageClick}
                        disabled={testStageIndex === 0}
                        variant="outlined"
                        sx={{
                          ml: '0.6rem',
                          pr: '1.2rem',
                          height: '2.1rem',
                          width: '5.5rem',
                          mr: isMobile ? '0.6rem' : 0
                        }}
                      >
                        <ArrowLeftIcon sx={{ position: 'relative', top: -1, left: -2 }} />
                        Prev
                      </Button>
                      <Button
                        onClick={handleNextPageClick}
                        disabled={typeof testStageIndex === 'number' && testStageIndex >= numStagePages - 1}
                        variant="outlined"
                        sx={{ mr: '0.6rem', pl: '1.2rem', height: '2.1rem', width: '5.5rem' }}
                      >
                        Next
                        <ArrowRightIcon sx={{ position: 'relative', top: -1, right: -2 }} />
                      </Button>
                    </Box>
                  )}
                  <Divider />
                  <ListSubheader sx={{ mt: 2 }}>Error States</ListSubheader>
                  {errorPageItems.map((pageData) => (
                    <MenuItem key={pageData[0]} value={pageData[0]}>
                      {pageData[1]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Tooltip title="SHIFT-CLICK: Continuous loading" show={!(simulateLoading || forceLoading)}>
                <FormControlLabel
                  label="Loading"
                  labelPlacement="top"
                  sx={{ ml: isMobile ? '0.3rem' : '1rem', mr: isMobile ? '-0.6rem' : '-0.4rem' }}
                  slotProps={{ typography: { sx: { fontSize: isMobile ? '0.8rem' : '0.9rem', lineHeight: '1.3' } } }}
                  control={<Switch checked={simulateLoading || forceLoading} onChange={handleSimLoadingChange} />}
                />
              </Tooltip>
            </>
          )}

          <Tooltip title="SHIFT-CLICK: Print userData to console">
            <FormControlLabel
              label="Tokens"
              labelPlacement="top"
              sx={{ ml: isMobile ? '0.3rem' : '1rem', mr: isMobile ? '0.8rem' : '1rem' }}
              slotProps={{ typography: { sx: { fontSize: isMobile ? '0.8rem' : '0.9rem', lineHeight: '1.3' } } }}
              control={<Switch checked={!demoTesting} onChange={handleUseTokenChange} />}
            />
          </Tooltip>
        </Box>
      </Slide>
    </Wrapper>
  );
}
