import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext } from 'react';

import { FFLoadingStateContext } from '@/components/featureFlags/FFLoadingStateProvider';
import { FF_PPP_ENABLED, FF_PPP_TESTING } from '@/constants/featureFlags';
import type ffDefaults from '@/constants/ffDefaults';

type PCPFlags = {
  pcpEnabled: boolean;
  pcpTestingEnabled: boolean;
};

export default function useFeatureFlags(): [boolean, PCPFlags] {
  const flags = { ...useFlags<typeof ffDefaults>() };
  const { loading: ffLoading } = useContext(FFLoadingStateContext);

  const pcpEnabled = !!flags[FF_PPP_ENABLED];
  const pcpTestingEnabled = pcpEnabled && !!flags[FF_PPP_TESTING];

  return [
    ffLoading,
    {
      pcpEnabled,
      pcpTestingEnabled
    }
  ];
}
