import { useCallback } from 'react';
import TagManager from 'react-gtm-module';

import type { PCPGa4Event } from '@/pcp/constants/types';
import Logger from '@/utils/logger';

const logger = new Logger('useGoogleAnalytics.ts');

export default function useTracking() {
  // copied from forms `useGoogleTagManager` in prep for PCP migration
  const sendGoogleAnalyticsEvent = useCallback((eventName: string, params: Record<string, string>) => {
    logger.debug('Sending Google Analytics event', eventName, params);

    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
        ...params
      }
    });
  }, []);

  const sendGa4Events = useCallback((events?: PCPGa4Event[]) => {
    if (!events || events.length === 0) {
      return;
    }
    events.forEach((event) => {
      const { event: eventName, ...eventParams } = event;
      sendGoogleAnalyticsEvent(eventName, eventParams);
    });
  }, []);

  return { sendGa4Events };
}
